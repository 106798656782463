import { defineStore } from "pinia";
import iAxios from "./axiosInterface";
import localforage from "localforage";
import { ref, reactive } from "vue";

export const userStore = defineStore("userStore", {
  state: () => ({
    headerTitel: "",
    cid: null,
    whoAmIData: {},
    shareSpace: {},
    //Benutzerart
    isMainUser: false,
    isManagementUser: false,
    isAssistantUser: false,
    isWorkerUser: false,
    isStuffUser: false,
    //Benuzterrechte allgemein
    mainRights: false,
    managementRights: false,
    assistantRights: false,
    stuffRights: false,
    workerRights: false,

    loggedUserName: "",
    loggedIN: false,
    expired: false,
    registrationConfirmed: false,
    firstLogin: null,
    debug: false,
    dbgUser: false,
    registersStayOpen: false,
    tipEnabledList: {},
    companySettings: { extendedShopSettings: { shippingKinds: [], } },
    holdSidebarOpen: false,
    showVkSideDiv: false,
    vkMargen: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    dataStates: {
      cid: null,
      creditnotes: null,
      customers: null,
      invoices: null,
      items: null,
      letters: null,
      name: null,
      offers: null,
      orders: null,
    },
    itemMap: {},
    apiKeyStorage: null,
    itemEditSettings: { erweitert: false },
    //Modulrechte
    rules: {
      activatedModules: {
        dashboard: false,
        catalog: false,
        customer: false,
        faststart: false,
        offer: false,
        order: false,
        invoice: false,
        paymentReminder: false,
        businessLetter: false,
        creditNote: false,
        picking: false,
        calendar: false,
        regulations: false,
        navigation: false,
        addItem: false,
        EkView: false,
        offerEdit: false,
        settings: false,
        reports: false,
        help: false,
        support: false,
        email: false,
        e_invoice: false,
        mhdTracking: false,
        chargeTracking: false,
        logoUpload: false,
        stampUpload: false,
        signatureUpload: false,
        bgRemove: false,
        itemDescription: false,
        shipmentTracking: false,
        stock: false,
        inventory: false,
        shipping: false,
        shippingWeight: false,
        shippingZone: false,
        shippingLiveRate: false,
        shippingOversized: false,
        subAccounts: 0,
        item_vk: 0,
        shopify: false,
        shop: false,
        supportLevel: 0,
        chat: false,
        notes: false,
        employees: false,
        documents: false,
        sms: false,
        push: false,
        whiteLabelApp: false,
        payment: false,
        accounting: false,
        projects: false,
        tasks: false
      }
    },
    inventoryData: {
      type: "STOCKINVENTORY",
      itemList: [],
      docContent: {
        notes: null,
      },
    },
    localForageInitialized: false,
  }),

  getters: {
    getShareSpace(state) {
      return state.shareSpace;
    },
    getItemMap(state) {
      return state.itemMap;
    },
    getDebug(state) {
      return state.debug;
    },
    getWhoAmIData(state) {
      return state.whoAmIData;
    },
    getCategories(state) {
      if (!state.companySettings.categories?.list) {
        state.companySettings.categories = {
          list: [],
        };
      }
      return state.companySettings.categories.list;
    },
    getCompanyTips(state) {
      return state.tipEnabledList;
    },
    isAuthenticated(state) {
      return !!state.cid;
    },
    isFirstLogin(state) {
      return state.firstLogin;
    },
  },

  actions: {
    async initLocalForage() {
      if (this.localForageInitialized) return;

      const stateKeys = Object.keys(this.$state);
      try {
        const persistedState = await Promise.all(
          stateKeys.map(async (key) => {
            if (key === "localForageInitialized") {
              console.warn(`Schlüssel "${key}" wird ignoriert.`);
              return;
            }
            const value = await localforage.getItem(key);
            if (value !== null) {
              this.$state[key] = JSON.parse(JSON.stringify(value));
            }
          })
        );
        this.localForageInitialized = true;
      } catch (error) {
        console.error("Fehler beim Initialisieren von LocalForage:", error);
      }
    },

    async persistState() {
      const stateKeys = Object.keys(this.$state);
      try {
        await Promise.all(
          stateKeys.map(async (key) => {
            let value = this.$state[key];
            if (key === "localForageInitialized") {
              //console.warn(`Schlüssel "${key}" wird nicht persistiert.`);
              return;
            }
            try {
              value = JSON.parse(JSON.stringify(value));
            } catch (error) {
              console.error(`Fehler: Der Zustand "${key}" ist nicht serialisierbar:`, value);
              throw error;
            }
            await localforage.setItem(key, value);
          })
        );
      } catch (error) {
        console.error("Fehler beim Persistieren des Zustands in LocalForage:", error);
      }
    },

    async signin(payload) {
      const signinDO = {
        ...payload,
        mode: "signin",
      };
      try {
        const url = process.env.VUE_APP_BASE_API_URL + "/login";
        const response = await iAxios.post(url, signinDO);
        await this.resetStates();
        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        await this.whoAmI();
        await this.loadCompanyTips();
        await this.persistState();
        return response;
      } catch (error) {
        console.error("Fehler beim Anmelden:", error);
        throw error;
      }
    },

    async signup(payload) {
      const signupDO = {
        ...payload,
        mode: "signup",
      };
      try {
        const url = process.env.VUE_APP_BASE_API_URL + "/register";
        const response = await iAxios.post(url, signupDO);
        this.cid = response.data.cid;
        await this.persistState();
        return response;
      } catch (error) {
        console.error("Fehler beim Registrieren:", error);
        throw error;
      }
    },

    setRegistrationConfirmed(bool) {
      this.registrationConfirmed = bool;
    },

    setDebug(bool) {
      this.debug = bool;
    },

    async setCategories(categories) {
      try {
        this.categories = { list: { categories: categories } };
        const url = process.env.VUE_APP_BASE_API_URL + '/company/settings';
        const formData = { categories: { list: categories } };
        const res = await iAxios.put(url, formData);
        return res;
      } catch (e) {
        console.error(e);
      }
    },

    async loadCompanyTips() {
      try {
        let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
        const response = await iAxios.get(url);
        const tips = response.data.tipsList;
        Object.assign(this.tipEnabledList, tips);
        return this.tipEnabledList;
      } catch (e) {
        console.error(e);
      }
    },

    async setCompanyTips(tipEnabledList) {
      try {
        let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
        const response = await iAxios.post(url, { tipsList: tipEnabledList });
        const tips = {};
        tips.tipsList = response.data;
        return tips;
      } catch (e) {
        console.error(e);
      }
    },


    async getCompanySettings() {
      let url = "/company/settings";
      try {
        const response = await iAxios.get(url);
        Object.assign(this.companySettings,response.data);
        console.log("🚀 ~ file: userStore.js:475 ~ this.companySetting:", this.companySettings)
       // this.companySettings = response.data;
        return response;
      } catch (error) {
        console.error("Fehler beim Abrufen der Unternehmenseinstellungen:", error);
      }
    },

    async setCompanySettings(body) {
      try {
        let url ="/company/settings";
        const response = await iAxios.put(url, body);

        console.log("🚀 ~ file: userStore.js:302 ~ response:", response)

        //Object.assign(this.companySettings,response.data);
        return response.data;
      } catch (e) {
        console.error(e);
      }
    },

    async resetPassword(emailAddress4ResetPassword) {
      let url = process.env.VUE_APP_BASE_API_URL + "/reset-pass/" + emailAddress4ResetPassword;
      try {
        const response = await iAxios.get(url);
        if (response.status === 200) {
          return response;
        }
      } catch (error) {
        if (error.response?.status === 403) {
          return error.response;
        }
      }
    },

    async resetStates() {
      this.cid = null;
      this.expired = true;
      this.loggedIN = false;
      this.whoAmIData = null;
      this.loggedUserName = "";
      this.dataStates.customers = null;
      this.dataStates.items = null;
      this.dataStates.cid = null;
      this.itemMap = {};
      this.companySettings = {};
      this.rules.activatedModules = {};
      this.firstLogin = null;
      this.isMainUser = false;
      this.isManagementUser = false;
      this.isAssistantUser = false;
      this.isStuffUser = false;
      this.isWorkerUser = false;
      this.mainRights = false;
      this.managementRights = false;
      this.assistantRights = false;
      this.stuffRights = false;
      this.workerRights = false;
      this.registeredStayOpen = false;
      this.registrationConfirmed = false;
      this.dbgUser = false;
      this.tipEnabledList = {};
      this.localForageInitialized = false;
      await localforage.clear();
      await this.persistState();
    },

    async logout() {
      console.log("Logout");
      this.resetStates();
      let url = process.env.VUE_APP_BASE_API_URL + "/logout";
      try {
        await iAxios.get(url);
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },

    setExpired(bool) {
      this.expired = bool;
      if (bool === true) this.loggedIN = false;
    },

    setHeaderTitel(titelText) {
      this.headerTitel = titelText;
    },

    setShareSpace(shareSpace) {
      this.shareSpace = shareSpace;
    },

    /*     setItems(items) {
          this.items = JSON.parse(JSON.stringify(items));
          let itemMap = {};
          items.forEach(o => itemMap[o.item_id_company] = o);
          this.itemMap = itemMap;
        }, */

    /*    setCustomers(customers) {
   
         console.log("🚀 ~ file: userStore.js:317 ~ customers:", customers)
   
         this.customers = JSON.parse(JSON.stringify(customers));
   
         console.log("🚀 ~ file: userStore.js:321 ~ this.customers:", this.customers)
   
       }, */

    async mapItems() {
      let itemMap = {};
      const items = await this.getDataFromStorage("items");
      items.forEach(o => itemMap[o.item_id_company] = o);
      this.itemMap = itemMap;
    },

    async forageCheckForKey(key) {
      try {
        const value = await localforage.getItem(key);
        return Boolean(value);
      } catch (error) {
        console.info('Keine Daten im Forage');
        return false;
      }
    },

    /**
     * 
     * @param {*} key - Der Schlüssel, unter dem die Daten gespeichert werden sollen 
     * @param {*} data - Die Daten, die gespeichert werden sollen
     */
    async setDataToStorage(key, data) {
      try {
        // Serialisierung der Daten
        const serializedData = JSON.stringify(data);

        //löschen von key inhalt vom localforage
        await localforage.removeItem(key);
        if (this.debug) console.log("removed " + key + " form localforage");

        // Speichern in localForage
        await localforage.setItem(key, serializedData);

        // Aktualisierung des lokalen Zustands
        //this.customers = customers; so geht nix, localstrage nur 5mb

        if (this.debug) console.log("Daten erfolgreich in localForage gespeichert.");
      } catch (error) {
        console.error("Fehler beim Speichern der Kunden:", error);
      }
    },

    /**
     * Lädt die Daten aus localForage
     * @param {string} key - Der Schlüssel, unter dem die Daten gespeichert sein sollen, "customers","items",..
     */
    async getDataFromStorage(key) {
      try {
        // Kunden aus localForage laden
        const serializedData = await localforage.getItem(key);

        // Falls vorhanden, den Zustand aktualisieren
        if (serializedData) {
          const data = JSON.parse(serializedData);
          if (this.debug) console.log("Daten erfolgreich aus localForage geladen.");
          return data;
        } else {
          if (this.debug) console.warn("Keine Daten im Speicher gefunden.");
        }
      } catch (error) {
        console.error("Fehler beim Laden der Daten:", error);
      }
    },

    async whoAmI() {
      let url = process.env.VUE_APP_BASE_API_URL + "/whoami";
      try {
        const response = await iAxios.get(url);
        this.whoAmIData = response.data;
        Object.assign(this.rules.activatedModules, response.data.rules.activatedModules);
        //console.log("🚀 ~ file: userStore.js:415 ~ this.rules:", this.rules)

        this.loggedUserName = response.data.userName;
        this.isMainUser = response.data.role === "ADMIN";
        this.mainRights = response.data.role === "ADMIN";
        this.isManagementUser = response.data.role === "MANAGEMENT";
        this.managementRights = response.data.role === "MANAGEMENT" || this.isMainUser;
        this.isAssistantUser = response.data.role === "ASSISTANT";
        this.assistantRights = response.data.role === "ASSISTANT" || this.isManagementUser;
        this.isStuffUser = response.data.role === "STUFF";
        this.stuffRights = response.data.role === "STUFF" || this.isAssistantUser;
        this.isWorkerUser = response.data.role === "WORKER";
        this.workerRights = response.data.role === "WORKER" || this.isStuffUser;

        this.cid = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        await this.getCompanySettings();
        return response.data;
      } catch (error) {
        this.cid = null;
        this.loggedIN = false;
        this.expired = true;
      }


    },


    setHoldSidebarOpen(bool) {
      this.holdSidebarOpen = bool;
    },

    setShowVkSideDiv(bool) {
      this.showVkSideDiv = bool;
    },

    setVkMargen(vkMargen) {
      this.vkMargen = vkMargen;
    },

    setDataStates(data) {
      this.dataStates = data;
    },

    setItemEditSettings(settings) {
      this.itemEditSettings = settings;
    },

    setApiKeyStorage(apiKey) {
      this.apiKeyStorage = apiKey;
    },

    async refreshUserData() {
      try {
        await this.whoAmI();
        console.log("Mitarbeiter aktualisiert.");
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Mitarbeiter:", error);
      }
    },

    async clearLocalForage() {
      try {
        await localforage.clear();
        const stateKeys = Object.keys(this.$state);
        stateKeys.forEach((key) => {
          this.$state[key] = Array.isArray(this.$state[key]) ? [] : null;
        });
      } catch (error) {
        console.error("Fehler beim Löschen der LocalForage-Daten:", error);
      }
    },

    checkStateSerializability() {
      const stateKeys = Object.keys(this.$state);

      stateKeys.forEach((key) => {
        const value = this.$state[key];

        try {
          JSON.stringify(value);
        } catch (error) {
          console.error(`Nicht serialisierbarer Zustand "${key}":`, value);
        }
      });
    },

    setDbgUser(bool) {
      this.dbgUser = bool;
    },

    async setTipEnabledList(tipList) {
      this.tipEnabledList = tipList;
      await this.persistState();
    },

    async updateCompanySettings(settings) {
      try {
        const url = process.env.VUE_APP_BASE_API_URL + "/company/settings";
        const response = await iAxios.put(url, settings);
        this.companySettings = response.data;
        await this.persistState();
        return response;
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Unternehmenseinstellungen:", error);
      }
    },

  },

  persist: true,
});
