<template>
  <Loading v-show="loading" />
  <div class="w-full">
    <AutoComplete
      v-model="selectedItems"
      optionLabel="itemName"
      :placeholder="props.placeholder"
      :suggestions="filteredItems"
      dataKey="item_id_company"
      :inputClass="props.inputClass"
      :inputId="props.inputId"
      :disabled="loading"
      completeOnFocusfilterItemsWithImage
      class="w-full"
      :loading="loading"
      v-tooltip="filterItemsWithImage?'Es werden nur Artikel, welche Bilder enthalten angezeigt':''"
      @complete="search"
      @item-select="selectItem($event)"
      ref="autocompleteRef"
      :inputProps="{autocomplete: 'off'}"
      @change="checkInput"
      @blur="autoSelectRollBack"
      @keydown.enter="selectionWithEAN"
      @refreshAndSelect="refreshAndSelect"
      :virtualScrollerOptions="{ itemSize: 70 }">
      <template #option="slotProps">
        <div
          class="align-options-center"
          @mouseover="showSubMenu[slotProps.option.item_id_company] = true"
          @mouseleave="showSubMenu[slotProps.option.item_id_company] = false">
          <span class="flex">
            <div
              :class="props.optionClass"
              v-html="
                highlightMatch(slotProps.option.itemName, selectedItems)
              "></div>
          </span>
          <!-- Submenu -->
          <div
            v-if="props.showVK"
            v-show="showSubMenu[slotProps.option.item_id_company]"
            class="submenu">
            <div class="flex" style="position: relative; top: 20px">
              <div
                v-for="(price, index) in slotProps.option.prices"
                :key="index">
                <Button
                  outlined
                  size="small"
                  class="text-xs font-light mr-2 px-1 py-1"
                  :label="price.priceType"
                  v-tooltip="price.price.toFixed(2) + ' €'"
                  @click="selectPrice(slotProps.option, price)" />
              </div>
            </div>
          </div>
          <!--           <span v-if="slotProps.option.inputKind == 'Katalogartikel'">
            <span
              class="material-symbols-outlined text-lg relative text-teal-400"
              style="top: 4px"
              :class="props.optionClass"
              >two_pager
            </span>
            &nbsp;
          </span>
          <span v-else>
            <i class="pi pi-building text-blue-400"></i>&nbsp;
          </span>

          <small :class="props.secondOptionClass"
            >{{ slotProps.option.inputKind }}
          </small> -->
          <small>
            {{ slotProps.option.stock }} x {{ slotProps.option.itemUnit }}
            <i class="pi pi-tag text-orange-400"></i>&nbsp;
          </small>
          <small> EAN:{{ slotProps.option.EAN }} </small>
        </div>
      </template>
      <template #header v-if="props.showAddNewItem">
        <Button
          :class="props.optionClass"
          class="text-color-secondary w-full h-8 font-light text-left border-noround line-height-3"
          text
          label="Neuen Artikel erstellen"
          icon="pi pi-plus"
          @click="openItemEditDialog" />
      </template>
      <template #empty>
        <div class="p-2">Keine Artikel gefunden</div>
        <Button
          :class="props.optionClass"
          class="text-color-secondary w-full h-8 font-light text-left border-noround line-height-3"
          text
          label="Artikelliste neu laden"
          icon="pi pi-refresh"
          @click="werteLaden(true)" />
      </template>
    </AutoComplete>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  defineAsyncComponent,
  reactive,
  markRaw,
  onBeforeMount,
  watch,
  onUnmounted,
} from "vue";
import { ItemsService } from "@/service/ItemsService";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import { useDialog } from "primevue/usedialog";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import Loading from '@/components/Loading.vue';

const store = userStore();
const { debug, companySettings, itemsLoaded } = storeToRefs(store);
const toast = useToast();
const props = defineProps({
  showVK: {
    type: Boolean,
    default: true,
  },
  filteredObject: {
    type: Object,
    default: null,
  },
  modelValue: {
    default: null,
  },
  placeholder: {
    type: String,
    default: "Artikel suchen",
  },
  inputClass: {
    type: String,
    default: "text-xs w-full",
  },
  optionClass: {
    type: String,
    default: "text-sm",
  },
  secondOptionClass: {
    type: String,
    default: "text-xs",
  },
  showAddNewItem: {
    type: Boolean,
    default: true,
  },
   customer: {
    type: Object,
    default: null,
  },
  inputId: {
    type: String,
    default: "itemInputSearch",
  },
  loadFromStorage: {
    type: Boolean,
    default: false,
  },
  filterItemsWithImage:{
    type: Boolean,
    default: false,
  }
});


//props ist nötig, damit die Komponente als Input verwendet werden kann, damit sie mit v-model verwendet werden kann
//wegen props.modelValue wird die Komponente als Input verwendet. Ansonsten können die props auch ohne props deklariert werden
//Beispiel: const { modelValue, inputClass, optionClass, secondOptionClass } = defineProps({ modelValue: Object, inputClass: String, optionClass: String, secondOptionClass: String })
// so würde ein Abrufen ohne props funktionieren, aber wegen v-model muss props.modelValue verwendet werden

const dialog = useDialog();

const ItemInsertComponent = defineAsyncComponent(() =>
  import("@/views/ItemInsertComponent.vue")
);
const ItemsEditHeader = defineAsyncComponent(() =>
  import("@/views/ItemsEditHeader.vue")
);

const DefaultFooter = defineAsyncComponent(() =>
  import("@/views/ItemsEditFooter.vue")
);
const autocompleteRef = ref(null);
const filteredItems = ref();
//const selectedItems = ref(null);
const emit = defineEmits(["update:modelValue", "inputSelected", "fastInput","clearInput","blur"]);
//emit mit getCurrentInstance nicht möglich und nötig, weil es ein functional component ist

//Weil es ein Komponenten-Input ist muss das selectedItems-Objekt als computed deklariert werden, damit es reaktiv ist und mit v-model verwendet werden kann, damit es auch von außen verändert werden kann
//burda beschrieben https://vuejs.org/guide/components/v-model.html#usage-with-v-model
const selectedItems = computed({
  get() {
    return props.modelValue;
  },
  set(selectedItems) {
    emit("update:modelValue", selectedItems);
  },
});
const items = ref();

const showSubMenu = ref({});
const selectPrice = (item, price) => {
  item.manuelPrice = price.price;
  item.manuelPriceType = price.priceType;
};

const autoSelectRollBack = (event) => {
  console.log("🚀 ~ file: ItemInputSearch.vue:199 ~ event:", event.srcElement.value)
  emit("blur", event.srcElement.value);

  /*       console.log("🚀 ~ file: ItemInputSearch.vue:193 ~ selectedItems:", selectedItems)
  if (selectedItems.value && typeof selectedItems.value !== "object") {
    const nullItem=ref({});
    nullItem.value.itemName = selectedItems.value;
    nullItem.value.item_id_company = null;
    nullItem.value.inputKind = null;
    nullItem.value.stock = null;
    nullItem.value.EAN = null;
    nullItem.value.manuelPrice = null;
    nullItem.value.manuelPriceType = null;
    nullItem.value.SKU = null;
    nullItem.value.itemCustomId = null;  
    emit("inputSelected", nullItem)
  } */
};

/* const enterKeyCheck = (event) => {
  if (event?.key === "Enter") {
    event.preventDefault();
    selectionWithEAN(event);
    return;
  }
};
 */
const search = (event) => {
  if (!event) return;
  if (!items || !items.value) return;
  setTimeout(() => {
    if (!event.query.trim().length) {
      if (props.filteredObject) {
        let tempFiltered = [];
        items.value.forEach(element => {
          if (element.item_id_company !== props.filteredObject.item_id_company) {
            tempFiltered.push(element);
          }
        });
        filteredItems.value = [...tempFiltered];
      } else {
        filteredItems.value = [...items.value];
      }
    } else {
      filteredItems.value = items.value.filter((item) => {
        const query = event.query.toLowerCase();
        if (props.filterItemsWithImage && item?.itemImageUrl?.length) return false;
        return (
          item?.itemName?.toLowerCase().includes(event.query.toLowerCase()) ||
          item?.EAN?.includes(event.query) ||
          item?.SKU?.toLowerCase().includes(event.query.toLowerCase()) ||
          item?.itemCustomId
            ?.toLowerCase()
            .includes(event.query.toLowerCase()) ||
          item?.VPE?.list?.VPE_EAN?.includes(event.query) || (props.filteredObject && props.filteredObject === item) ||
          item?.searchTag?.tags?.some(tag => tag.toLowerCase().includes(query))
        );
      });
    }
  }, 10);
};

const checkInput = (event) => {
  if (event.value.length < 1) {
    emit("clearInput");
  }
}

const selectionWithEAN = (event) => {
  console.log("--> selectionWithEAN:", event.target.value);
  if (event.target.value.length < 3) return;
  event.preventDefault();
  let item = items.value.find((item) => item.EAN === event.target.value);
  console.log("🚀 ~ file: ItemInputSearch.vue:224 ~ item:", item)

  //suche nach der normalen EAN
  if (item) {
    item.isVPE = item?.VPE?.list?.length > 0;
    item.isVPE_EAN = false;
    selectedItems.value = item;
    emit("inputSelected", item);
    return;
  }

  //suche nach der VPE EAN
  item = items.value.find((item) => 
  item?.VPE?.list?.find((vpe) => String(vpe.VPE_EAN) === event.target.value)
);
  if (item) {
    const vpe = item.VPE.list.find((vpe) => String(vpe.VPE_EAN) === event.target.value);
    item.isVPE = true;
    item.VPE_QTY = vpe.VPE_QTY;
    item.isVPE_EAN = true;
    selectedItems.value = item;
    emit("inputSelected", item);
    return;
  }
};

const loading = ref(true);

const highlightMatch = (text, keyword) => {
  if (typeof keyword !== "string") {
    return text;
  }

  try {
    const regex = new RegExp(`(${keyword})`, "gi");
    // Verwenden von replace, um das Keyword durch markierten Text zu ersetzen
    return text.replace(
      regex,
      (_, match) => `<span class="highlightText">${match}</span>`
    );
  } catch (error) {
    return text;
  }
};
const itemData = ref();
const openItemEditDialog = async (event) => {
  const newitemData = reactive({
    item_id_company: -1,
    inputKind: "Katalogartikel",
    itemAlias: "",
    showAlias: false,
    created_de: "",
    created_us: "",
    updated_de: "",
    updated_us: "",
    itemActive: true,
    itemCustomId: "",
    itemName: "",
    itemInPrice: 0,
    isVPE: false,
    VPE: {
      list: [],
    },
    VPE_QTY: 0,
    itemOutPrice: 0,
    itemUnit: "Stk.",
    itemTaxId: companySettings.value.defaultTaxId,
    itemTaxValue: companySettings.value.defaultTaxValue,
    itemType: "goods",
    itemParameters: {
      weight: 0,
      linkedItem: null,
      forbidSingleSale: false,
      discount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    history: {
      quantity: 0,
      lastPurchase: null,
      lastSale: null,
    },
    category: reactive({ path: "", selectedKey: "", structure: null }),
    stock: 0,
    searchTag : { },
    rabatt: 0,
  });
  let itemData = JSON.parse(JSON.stringify(newitemData));

  dialog.open(ItemInsertComponent, {
    props: {
      header: "Artikel hinzufügen",
      modal: true,
      closable: false,
      style: {
        width: "45vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      //markRaw ist nötig, damit die Komponente nicht als reactive deklariert wird, weil sonst die Komponente nicht gerendert wird
      header: markRaw(ItemsEditHeader),
      footer: markRaw(DefaultFooter),
    },
    data: {
      modulCall: true,
      //modulCall ist nötig, damit die Komponente weiß, dass sie nicht aus dem Modul aufgerufen wird, sondern aus einer anderen Komponente
      item: itemData,
      isNewItem: true,
      erweitert: store.itemEditSettings.erweitert
    },
    onClose: async (options) => {
      {
        console.log("🚀 ~ file: ItemInputSearch.vue:153 ~ options", options);
      }
      if (options.data) {
        const buttonType = options.data.buttonType;
        const item_id_company = options.data.item_id_company;
        if (buttonType == "Save") {
          const item = await save(itemData);
          refreshAndSelect(item);
        } else if (buttonType == "Cancel") {
        }

        if (debug.value)
          console.log(
            "🚀 ~ file: ItemInputSearch.vue:205 ~ itemData:",
            itemData
          );
      }
    },
  });
};

const save = async (item) => {
  try {
    const url = process.env.VUE_APP_BASE_API_URL + "/products/item";
    const response = await iAxios.post(url, item);
    item.item_id_company = response.data.item_id_company;
    if (debug.value)
      console.log("🚀 ~ file: ItemInputSearch.vue:205 ~ response:", response);
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Fehler beim Speichern des Artikels",
    });
    //Object.assign(item, originalData);
    return item;
  }
};

const refreshAndSelect = async () => {
  //diese Funktion wird aufgerufen, wenn ein neuer Artikel erstellt wurde, damit der neue Artikel in der Liste angezeigt wird und ausgewählt wird
  await werteLaden();
  selectedItems.value = await items.value[items.value.length - 1];
  emit("inputSelected", selectedItems.value);
};

defineExpose({
  refreshAndSelect,
});

/* 
//Falls beschlossen wird in der Auswahl auch die gekaufte Menge anzuzeigen auskommentieren und 
in invoiceEditComponent OrderEdit.. und OfferEdit..  folgende Erweiterung einfügen
... <ItemInputSearch   ...    customer: selectedCustomer, /> 

//dann folgenden Watcher hier auskommentieren
watch(() => props?.customer, async (newCustomer) => {
  const newKundenNummer = newCustomer?.kundenNummer;
  console.log("🚀 ~ file: ItemInputSearch.vue:418 ~ newKundenNummer:", newKundenNummer)

  if (newKundenNummer) {
    const itemHistory = await iAxios.get("/products/item-history-customer/" + newKundenNummer);

    console.log("🚀 ~ file: ItemInputSearch.vue:420 ~ itemHistory:", itemHistory)

    items.value = items.value.map((itemList) => {
      // Finden Sie das Element in itemHistory
      const historyItem = itemHistory?.data?.find(history => history.item_id_company === itemList.item_id_company);

      return {
        ...itemList,
        // Fügen Sie die history Eigenschaft hinzu, wenn das Element in itemHistory gefunden wurde
        history: historyItem ? { quantity: historyItem.data.quantity } : null,
      };
    });
  }
}, { immediate: true }); // immediate: true sorgt dafür, dass der Watcher sofort beim Initialisieren ausgeführt wird */

const werteLaden = async (showLoading) => {
  if (showLoading) loading.value = true;
  console.log("🚀🚀🚀🚀🚀 ~ file: ItemInputSearch.vue:527 ~ props.loadFromStorage:", props.loadFromStorage)

  await ItemsService.getItemsXLarge(props.loadFromStorage).then((data) => {
    items.value = data.map((item) => {
      const prices = [];

      if (props.showVK && !item?.isDeposit) { //neu !isDeposit 09.12.2024 13,35
        if (item.itemOutPrice) {
          prices.push({
            price: item.itemOutPrice,
            priceType: "VK-1",
          });
        }
        if (item.vk2) {
          prices.push({
            price: item.vk2,
            priceType: "VK-2",
          });
        }

        if (item.vk3) {
          prices.push({
            price: item.vk3,
            priceType: "VK-3",
          });
        }

        if (item.vk4) {
          prices.push({
            price: item.vk4,
            priceType: "VK-4",
          });
        }
      }

      return {
        ...item,
        prices,
        manuelPriceType: null,
      };
    });
  });
  items.value.sort((a, b) => a.item_id_company - b.item_id_company);
  if (showLoading) loading.value = false;
};



const loadItemHistory=(customerUid)=>{
  ItemsService.getItemHistoryCustomer(customerUid).then((data) => {
    itemData.value = data;
    console.log("🚀 ~ file: ItemInputSearch.vue:463 ~ data:", data)
  });
}



onBeforeMount(async () => {
    if (debug.value) console.log("Lade items...");
    await werteLaden();
  loading.value = false;
});


const selectItem = async (event) => {
  const promises = [];
  if (event?.value?.itemParameters?.linkedItem) {
    const promise = ItemsService.getItem(event.value.itemParameters.linkedItem.item_id_company).then((data) => {
      // aktualisiere die Daten des Artikels in items
      event.value.itemParameters.linkedItem = data;
    });
    promises.push(promise);
  }

  if (event?.value?.VPE?.list?.length > 0) {
    event.value.VPE.list.forEach((item, i) => {
      if (!item?.linkedItem?.item_id_company) return;
      const promise = ItemsService.getItem(item.linkedItem.item_id_company).then((data) => {
        // aktualisiere die Daten des Artikels in items
        event.value.VPE.list[i].linkedItem = data;
      });
      promises.push(promise);
    });
  }

  await Promise.all(promises);
  emit("inputSelected", event);
};


/* onUpdated(() => {
  const parentComponent = getCurrentInstance().parent;
  if (parentComponent && parentComponent.refreshAndSelect) {
    refreshAndSelect();
  }
}); */
</script>
<style scoped>
.submenu {
  position: absolute;
  /* Add your own styles... */
}
</style>
