<template>
  <Tip tipId="settingsTip" target="#settingsButton" :overlay="true" />
  <Tip
    v-if="showAbmeldenTip"
    tipId="logOutTip"
    target="#buttonLogout"
    :overlay="true" />

  <div class="card">
    <div class="accordion-container">
      <div class="left-column">
        <header>
          <Button
            text
            v-tooltip="
              'Kann wieder über Einstellungen->Erweitert-> Reiter >Schnellstart & Tipps< eingeschaltet werden'
            "
            label="Schnellstart beim nächsten Start, nicht mehr anzeigen."
            class="text-gray-400 text-sm"
            icon="pi pi-eye-slash"
            @click="toogleFastStartShow" />

          <h2 class="p-component"><strong>Willkommen bei Famtura</strong></h2>
          <div style="margin-top: -20px">
            <small
              >In dieser Checkliste sind die wichtigsten Schritte den Start
              enthalten.
            </small>
          </div>
        </header>
        <p></p>
        <h3 class="p-component">Einrichten des Firmenaccounts</h3>
        <Accordion class="accordion-custom" @tab-click="handleTabChange">
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (ausloggenFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>So meldest du dich ab</span>
            </template>
            <p>
              Klicke dazu einmal auf ausloggen, als Übung und melde dich danach
              erneut an. s. Bild
            </p>
            <img
              :src="require('@/assets/ausloggen-hilfe.jpg')"
              alt="ausloggen"
              style="width: auto; height: auto" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (firmenDatenFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Firmendaten eingeben</span>
            </template>
            <p>
              Prüfe die Angaben, wie Anschrift, Bankverbindung usw. zu deinem
              Unternehmen und ergänze diese falls nötig
            </p>
            <Button
              size="small"
              label="Einstellungen->Firmen-Profildaten"
              @click="
                checkStep('firmenDatenFast');
                $router.push('/CompanySettingsForm');
              " />
            <p>
              Diesen Bereich kannst du jederzeit Rechts Oben von den
              Einstellungen "Firmen-Profildaten" erreichen
            </p>
            <img
              :src="require('@/assets/einstellungen-profildaten-hilfe.jpg')"
              alt="Firmendaten eingeben"
              style="width: auto; height: auto" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (dokumentenEinstellungenFast && 'pi-check-circle') ||
                    'pi-circle',
                ]"></i>
              <span
                >Dokumenten-Einstellungen prüfen, Firmenlogo, Unterschrift,
                Stempel ...</span
              >
            </template>
            <p>
              In diesem Bereich kannst du die Dokumenten-Einstellungen
              einrichten.
            </p>
            <p>
              Firmenlogo hochladen und positionieren, Unterschrift hinterlegen
              oder hochladen, Stempel hochladen, die Nummerierung der Dokumente
              einrichten wie z.b. Rechnungsnr., Lieferscheinnr. usw. Du solltest
              einmal alle Einstellungen zu mindestens einmal durchgehen bzw. Dir
              ansehen.
            </p>
            <Button
              size="small"
              label="Einstellungen->Erweitert->Dokumenten-Einstellungen"
              @click="
                checkStep('dokumentenEinstellungenFast');
                $router.push('/Settings');
              " />
            <p>
              Diesen Bereich kannst du jederzeit Rechts Oben von den
              Einstellungen "Erweitert" und dann auf den Reiter
              "Dokumeteneinstellungen" erreichen
            </p>
            <img
              :src="require('@/assets/einstellungen-erweitert-hilfe.jpg')"
              alt="erweiterte einstellugnen eingeben"
              style="width: auto; height: auto" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (MitarbeiterFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Mitarbeiter prüfen/einpflegen</span>
            </template>
            <p>
              In diesem Bereich kannst du deine Personenbezogenen Mitarbeiter
              wie Vorname, Name und Antwort-Emailadresse einrichten.
            </p>
            <Button
              size="small"
              label="Einstellungen->Mitarbeiter"
              @click="
                checkStep('MitarbeiterFast');
                $router.push('/UserSettingsForm');
              " />
            <p>
              Diesen Bereich kannst du jederzeit Rechts Oben von den
              Einstellungen "Mitarbeiter" erreichen
            </p>
            <img
              :src="require('@/assets/einstellungen-benutzerdaten-hilfe.jpg')"
              alt="Mitarbeiter eingeben"
              style="width: auto; height: auto" />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (rechtsTexteFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Rechtstexte eingeben</span>
            </template>
            <p>
              Prüfe die Rechtstexte wie z.B. AGB,Datenschutz Hinweise usw. zu
              deinem Unternehmen und ergänze diese falls nötig
            </p>
            <Button
              size="small"
              label="Rechtstexte"
              @click="
                checkStep('rechtsTexteFast');
                $router.push('/legalTextsSettingsForm');
              " />
            <p>
              Diesen Bereich kannst du jederzeit Rechts Oben von den
              Einstellungen "Erweitert" und dann auf den Reiter
              "Dokumeteneinstellungen" erreichen
            </p>
            <img
              :src="require('@/assets/einstellungen-rechtstexte-hilfe.jpg')"
              alt="Rechtstexte eingeben"
              style="width: auto; height: auto" />
          </AccordionTab>
        </Accordion>
        <h3 class="p-component pt-3">Mit Famtura arbeiten</h3>
        <Accordion class="accordion-custom">
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (artikelFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Artikel einpflegen</span>
            </template>
            <p>Lege einen neuen Artikel im Katalog an.</p>
            <Button
              size="small"
              label="Artikel anlegen"
              @click="
                checkStep('artikelFast');
                $router.push('/itemsView');
              " />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (customerFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Kunde anlegen, ansehen bearbeiten</span>
            </template>
            <p>
              In diesem Bereich kannst du einen neuen Kunden anlegen, vorhandene
              beabeiten, oder details ansehen.
            </p>
            <Button
              size="small"
              label="Kunde anlegen, ansehen bearbeiten"
              @click="
                checkStep('kundeFast');
                $router.push('/kundenview');
              " />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (offerFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Ein Angebot erstellen</span>
            </template>
            <p>
              Wenn bereits mind. ein Kunden und mind. ein Artikel angelegt
              wurde, kann ein Angebot,Auftrag oder Rechnung erstellt werden. Im
              Menü unter "Angebote" zu erreichen
            </p>
            <Button
              size="small"
              label="Angebot erstellen"
              @click="
                checkStep('offerFast');
                $router.push('/OffersView');
              " />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (versandFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Versand oder Lieferung vorbereiten</span>
            </template>
            <p>
              Bereite den Versand oder die Lieferung vor. Hier siehst du die
              Lieferung die zu erledigen sind. Du kannst hier eine Packliste
              drucken und die Lieferungen/Sendungen auch manuell als versedet
              markieren.
            </p>
            <Button
              size="small"
              label="Versand vorbereiten"
              @click="
                checkStep('versandFast');
                $router.push('/kundenview');
              " />
          </AccordionTab>
          <AccordionTab>
            <template #header>
              <i
                :class="[
                  'pi pr-2',
                  (versendenFast && 'pi-check-circle') || 'pi-circle',
                ]"></i>
              <span>Lieferung oder Versand tätigen</span>
            </template>
            <p>
              Scanne den QR-Code und markiere dadurch die versendeten
              Lieferungen automatisch als versendet.
            </p>
            <Button
              size="small"
              label="Versand vorbereiten"
              @click="
                checkStep('versendenFast');
                $router.push('/kundenview');
              " />
          </AccordionTab>
        </Accordion>
      </div>
      <!-- <div class="right-column"> -->
      <!-- Platz halter Rechte Spalte -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
const router = useRouter();
const toast = useToast();
const store = userStore();


const chbxValue = ref();

const showAbmeldenTip = ref(false);
const ausloggenFast = ref(true);
const firmenDatenFast = ref(false);
const dokumentenEinstellungenFast = ref(false);
const MitarbeiterFast = ref(false);
const rechtsTexteFast = ref(false);
const artikelFast = ref(false);
const offerFast = ref(false);
const pflegeHilfsMittelFast = ref(false);
const customerFast = ref(false);
const versandFast = ref(false);
const versendenFast = ref(false);

const { rules, tipEnabledList, debug } =
  storeToRefs(store);

const toogleFastStartShow = () => {
  if (!tipEnabledList.value.tipsList) {
    tipEnabledList.value.tipsList = {};
  }
  tipEnabledList.value.tipsList["fastStartVisible"] = false;
  store.setCompanyTips(tipEnabledList.value);
  toast.add({
    severity: "warn",
    summary: "Schnellstart",
    detail: "Schnellstart wird beim nächsten Start nicht mehr angezeigt. Die Änderung wird nach dem Abmelden und wieder Anmelden bemerkbar. Kann wieder über Einstellungen->Erweitert-> Reiter >Schnellstart & Tipps< eingeschaltet werden",
    life: 5000,
  });
  // console.log("🚀 ~ file: FastStart.vue:124 ~ toogleFastStartShow ~ tipEnabledList:", tipEnabledList)
};

const showTip = (tipId) => {
  return tipEnabledList?.value.tipsList?.[tipId];
};

onBeforeMount(async () => {
  if (rules.value.activatedModules.faststart) {
    if (showTip("fastStartVisible") == false) {
      router.push("/dashboard");
    }
  } else router.push("/invoicesView");
  checkTips();
});

const handleTabChange = (e) => {
  if (e.index === 0) {
    checkStep("ausloggenFast");
    showAbmeldenTip.value = true;
  }


};

const checkTips = () => {
  ausloggenFast.value = showTip("ausloggenFast");
  firmenDatenFast.value = showTip("firmenDatenFast");
  dokumentenEinstellungenFast.value = showTip("dokumentenEinstellungenFast");
  rechtsTexteFast.value = showTip("rechtsTexteFast");
  artikelFast.value = showTip("artikelFast");
  offerFast.value = showTip("offerFast");
  pflegeHilfsMittelFast.value = showTip("pflegeHilfsMittelFast");
  customerFast.value = showTip("customerFast");
  versandFast.value = showTip("versandFast");
  versendenFast.value = showTip("versendenFast");
};

const checkStep = (tipStep) => {
    console.log("🚀 ~ file: FastStart.vue:124 ~ checkStep ~ tipStep", tipStep);
  if (!tipEnabledList.value.tipsList) {
    tipEnabledList.value.tipsList = {};
  }
  tipEnabledList.value.tipsList[tipStep] = true;

  console.log("🚀 ~ file: FastStart.vue:376 ~ tipEnabledList.value:", tipEnabledList.value)

  store.setCompanyTips(tipEnabledList.value);
  //router.push('/CompanySettingsForm')
  checkTips();
};
</script>

<style scoped>
/* Stil für die Container */
.accordion-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

/* Stil für die linke Spalte */
.left-column {
  flex: 1;
  padding-right: 2rem;
  /* Abstand zur rechten Spalte */
  padding-left: 3rem;
}

/* Stil für den Titel */
.left-column h2 {
  font-size: 1.5rem;
}

/* Stil für die rechte Spalte */
.right-column {
  flex: 1;
  display: flex;
  align-items: center;
}

/* Stil für den Hilfe-Link */
.right-column a {
  text-decoration: none;
  color: blue;
}

.pi-circle {
  color: lightcoral;
  font-size: 1.5rem;
}

.pi-check-circle {
  color: lightgreen;
  font-size: 1.5rem;
}
</style>
