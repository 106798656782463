import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";
export const getItems = (data) => {
  return data;
};

export const ItemsService = {
  async getData(timeStamp) {
    const store=userStore();
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/item");
    const items = await response.data;
    store.dataStates.items=timeStamp;
    store.setDataToStorage("items",items);
    return Promise.resolve(items);
  },

  /**
   * VK Preise angepasst für den jeweiligen Kunden direkt vom BE
   * @param {*} customerUid 
   * @returns 
   */
  async getPredefinedData(customerUid) {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/itemlist/" + customerUid);
    const data = await response.data.itemList;
    return Promise.resolve(data);
  },

  async getItem(id) {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/the-item/" + id);
    const data = await response.data;
    return Promise.resolve(data);
  },

  async getItemHistoryCustomer(customerUid) {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/products/item-history-customer/" + customerUid);
    const data = await response.data;
    return Promise.resolve(data);
  },

  async getItemStockExist(itemList) {
    const fuckBody= {
      data: {itemList: itemList}
    }
    const response = await iAxios.post(process.env.VUE_APP_BASE_API_URL +"/products/check-item-stock-exist", fuckBody);
    const data = await response.data;
    return Promise.resolve(data);
  },

  getItemsSmall() {
    return Promise.resolve(this.getData().slice(0, 10));
  },

  getItemsMedium() {
    return Promise.resolve(this.getData().slice(0, 50));
  },

  async checkForChanges() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/change-stats ");
    const data = await response.data;
    /* console.log("🚀 ~ file: ItemsService.js:56 ~ data:", data) */

    return Promise.resolve(data);
  },

/*   async getItemsXLarge() {
    const store=userStore();
    const states=await this.checkForChanges();
    if (states.cid != store.dataStates.cid) {
      store.dataStates.cid=states.cid;
      return Promise.resolve(this.getData(states.items));
    }

    if (!states?.items || !store?.dataStates?.items || (new Date(states.items) > new Date(store.dataStates.items)) ){
      return Promise.resolve(this.getData(states.items));
    } else
      return store.getItems;
  }, */

  async getItemsXLarge(loadFromStorage) {
    const store = userStore();
    const key = "items";
    if (loadFromStorage) {
      return store.getDataFromStorage(key);
    }

    const states = await this.checkForChanges();
    const timestampBE = states?.items;
    const timestampFE = store?.dataStates?.items;

    if (store.debug) {
      console.log("🚀 ~ timestamp auf BE", timestampBE)
      console.log("🚀 ~ timestamp auf FE", timestampFE)
      console.log("🚀 ~ states:", states)
    }

    if (states.cid != store.dataStates.cid) {
      if (store.debug)console.log("CID geändert, hole Daten vom BE")
      store.dataStates.cid = states.cid;
      return Promise.resolve(this.getData(timestampBE));
    }

    const foragePassed = await store.forageCheckForKey(key);

    if (store.debug)console.log("🚀  ~ foragePassed:", foragePassed)

    if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
      if (store.debug)console.log("Hole "+key+" Daten vom BE", timestampBE, timestampFE)
      return Promise.resolve(this.getData(timestampBE));
    } else {
      if (store.debug)console.log("lade "+key+" vom Storage!")
      return store.getDataFromStorage(key);
    }
  },

  async getItemslistPredefinedVK(customerUid,kim) {
    return Promise.resolve(this.getPredefinedData(customerUid));
  },


};
