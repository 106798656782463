<template>
  <div>
    <div class="flex justify-content-between">
      <Button
        text
        class="text-blue-300 font-semibold"
        @click="activeView = 'day'"
        ><span class="hidden md:block">Tagesansicht</span
        ><span class="md:hidden">Tag</span></Button
      >
      <Button
        text
        class="text-green-300 font-semibold"
        @click="activeView = 'week'"
        ><span class="hidden md:block">Wochenansicht</span
        ><span class="md:hidden">Woche</span></Button
      >
      <Button
        text
        class="text-yellow-300 font-semibold"
        @click="activeView = 'month'"
        ><span class="hidden md:block">Monatsansicht</span
        ><span class="md:hidden">Monat</span></Button
      >
      <Button
        text
        class="text-purple-300 font-semibold"
        @click="activeView = 'year'"
        ><span class="hidden md:block">Jahresansicht</span
        ><span class="md:hidden">Jahr</span></Button
      >
    </div>
    <div class="full-height">
      <vue-cal
        ref="vuecal"
        v-model:active-view="activeView"
        v-model:selected-date="selectedDate"
        class=""
        today-button
        :dblclickToNavigate="false"
        :events="events"
        :time-from="8 * 60"
        :time-to="19 * 60"
        editable-events
        :disable-views="['years']"
        locale="de"
        cell-contextmenu
        show-time-in-cells
        show-all-day-events
        events-count-on-year-view
        hide-view-selector
        @cell-dblclick="handleDblClick" />
    </div>

    <Dialog :visible="showDialog" @hide="showDialog = false">
      <h3>Neues Event</h3>
      <div class="p-fluid">
        <div class="p-field">
          <label for="event-title">Titel</label>
          <InputText id="event-title" v-model="eventTitle" type="text" />
        </div>
        <div class="p-field">
          <label for="event-date">Datum</label>
          <input
            id="event-date"
            v-model="eventStart"
            type="datetime-local"
            class="p-inputtext" />
        </div>
        <div class="p-field">
          <label for="event-duration">Dauer (Minuten)</label>
          <input
            id="event-duration"
            v-model.number="eventDuration"
            type="number"
            class="p-inputtext" />
        </div>
      </div>
      <div class="p-d-flex p-jc-end">
        <Button label="Abbrechen" class="p-button-text" @click="resetDialog" />
        <Button label="Hinzufügen" class="p-button" @click="addEvent" />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import InputText from "primevue/inputtext";
import { onMounted, ref } from "vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import { format } from "date-fns";
import { CalendarService } from "@/service/CalendarService";
const vuecal = ref(null);
const activeView = ref("day");
const selectedDate = ref(new Date());
const events = ref([
  {
    start: new Date(),
    end: new Date(new Date().getTime() + 120 * 60000),
    title: "Mein neues Event",
  },
]);

const showDialog = ref(false);
const eventTitle = ref("");
const eventStart = ref(new Date().toISOString().slice(0, 16)); // Standardwert für datetime-local
const eventDuration = ref(60);

const roundToNearestHalfHour = (date) => {
  const minutes = date.getMinutes();
  const roundedMinutes = minutes < 30 ? 0 : 30;
  const roundedDate = new Date(date);

  roundedDate.setMinutes(roundedMinutes);
  roundedDate.setSeconds(0);
  roundedDate.setMilliseconds(0);

  return roundedDate;
};

const formatDateTime = (date) => {
  const roundedDate = roundToNearestHalfHour(date);
  return format(roundedDate, "yyyy-MM-dd'T'HH:mm");
};

const handleDblClick = (date) => {
  if (date) {
    eventStart.value = formatDateTime(date);
    showDialog.value = true;
  } else {
    console.error("Ungültiges Datum übergeben");
  }
};

const addEvent = () => {
  if (!eventTitle?.value?.length > 0) return;
  if (eventTitle.value && eventStart.value && eventDuration.value > 0) {
    CalendarService.createCalendarEntry({
      start: new Date(eventStart.value),
      duration: eventDuration.value,
      end: new Date(
        new Date(eventStart.value).getTime() + eventDuration.value * 60000
      ).toISOString(),
      calTitle: eventTitle.value,
    });
    events.value.push({
      start: new Date(eventStart.value),
      end: new Date(
        new Date(eventStart.value).getTime() + eventDuration.value * 60000
      ),
      title: eventTitle.value,
    });
    resetDialog();
  } else {
    console.error("Eventdaten sind unvollständig oder ungültig");
  }
};

const resetDialog = () => {
  eventTitle.value = "";
  eventStart.value = new Date().toISOString().slice(0, 16);
  eventDuration.value = 60;
  showDialog.value = false;
};

onMounted(() => {
  CalendarService.getCalendarEntries().then((data) => {
    events.value = data;
  });
});
</script>

<style scoped></style>
<style>
.vuecal__event {
  background-color: rgba(173, 216, 230, 0.5);
  box-sizing: border-box;
  padding: 5px;

  &.lunch {
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      #f2f2f2 10px,
      #f2f2f2 20px
    );
  }
}

.full-height {
  height: 80vh;
  display: flex;
}
</style>
