<template>
    <div>
      <!-- Canvas für die Verarbeitung -->
      <canvas ref="canvas" style="display: none" :willReadFrequently="true"></canvas>
  
      <!-- Ergebnis -->
      <div v-if="resultImage" class="mt-4">
        <h2 class="text-center">Ergebnis:</h2>
        <img :src="resultImage" alt="Bild" class="p-card shadow-2" />
        <InputText
          v-model="logoName"
          size="small"
          class="text-xs w-full"
          placeholder="Logo Bezeichnung">
        </InputText>
        <Button
          size="small"
          label="Partnerlogo übernehmen"
          class="mt-3 text-xs p-2"
          :icon="'pi pi-download'"
          @click="saveImage()" />
      </div>
  
      <!-- File Upload mit PrimeVue -->
      <div class="mt-2">
        <FileUpload
          mode="basic"
          name="partnerLogo[]"
          size="small"
          class="text-xs p-2"
          accept="image/*"
          chooseLabel="Bild auswählen"
          @select="onFileChange" />
      </div>
  
      <!-- InputSwitch zur Auswahl Graustufen -->
      <div class="mt-2 flex align-items-center">
        <label for="grayscaleSwitch" class="mr-2">Graustufen umwandeln:</label>
        <InputSwitch id="grayscaleSwitch" v-model="convertToGrayscale" />
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from "vue";
  import { useToast } from "primevue/usetoast";
  
  const emit = defineEmits(["onConverted"]);
  const toast = useToast();
  const resultImage = ref(null);
  const canvas = ref(null);
  const logoName = ref("");
  const convertToGrayscale = ref(true); // Standardmäßig Graustufen aktiviert
  
  const onFileChange = async (event) => {
    const file = event.files?.[0]; // PrimeVue liefert das hochgeladene Bild im files-Array
    if (!file) return;
  
    const img = new Image();
    const reader = new FileReader();
  
    reader.onload = (e) => {
      img.src = e.target.result;
    };
  
    img.onload = () => {
      const ctx = canvas.value.getContext("2d");
  
      // Berechne die neue Breite basierend auf 150px Höhe
      const targetHeight = 150;
      const aspectRatio = img.width / img.height;
      const targetWidth = targetHeight * aspectRatio;
  
      // Passe die Canvas-Größe an
      canvas.value.width = targetWidth;
      canvas.value.height = targetHeight;
  
      // Zeichne das Bild auf die Canvas
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
      if (convertToGrayscale.value) {
        // Hole die Bilddaten
        const imageData = ctx.getImageData(0, 0, targetWidth, targetHeight);
        const data = imageData.data;
  
        // Wandle die Pixel in Graustufen nach BT.601 um, ohne Transparenz zu überschreiben
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i];
          const g = data[i + 1];
          const b = data[i + 2];
          const alpha = data[i + 3]; // Transparenz beibehalten
  
          if (alpha > 0) { // Nur für nicht-transparente Pixel
            const gray = 0.299 * r + 0.587 * g + 0.114 * b;
            data[i] = data[i + 1] = data[i + 2] = gray; // Setze den Grauwert
          }
        }
  
        // Aktualisiere die Canvas mit den Graustufen-Daten
        ctx.putImageData(imageData, 0, 0);
      }
  
      // Speichere das resultierende Bild als Data-URL
      resultImage.value = canvas.value.toDataURL("image/png");
    };
  
    reader.readAsDataURL(file);
  };
  
  const saveImage = () => {
    if (!logoName.value?.length > 0) {
      toast.add({
        severity: "error",
        summary: "Fehler beim Speichern des Bildes",
        detail: "Bitte eine Bezeichnung für das PartnerLogo eingeben",
        life: 5000, // Dauer der Toast-Meldung in Millisekunden
      });
    } else {
      emit("onConverted", { name: logoName.value, image: resultImage.value });
      logoName.value = "";
      resultImage.value = null;
    }
  };
  </script>
  