export const kundenDatenKorrigieren = async (kundenDaten, isPrivat) => {
  if (!kundenDaten || !Array.isArray(kundenDaten) || kundenDaten.length === 0) {
    console.warn('Keine gültigen Kundendaten gefunden');
    return {
      korrigierteDaten: [],
      erfolgreicheKorrekturen: 0,
      problematischeEintraege: [],
      anzahlProblematischeEintraege: 0,
    };
  }

  const bekannteNachnamenPraefixe = [
    "von", "zu", "der", "de", "del", "di", "da", "la", "le", "du", "des", "van", "van de", "van den", "van der",
    "al", "el", "Grad", "ben", "bin", "ap", "mac", "mc", "Graf", "Fürst", "Herzog", "Prinz", "Freiherr", "Baron", "Count", "son"
  ];
  const anreden = ["herr", "frau", "dr", "prof", "professor", "doktor"];

  let problematischeEintraegeAnschrift = [];
  let problematischeEintraegeName = [];
  let problematischeEintraegeFirma = [];
  let erfolgreicheKorrekturen = 0;

   let korrigierteDaten = await Promise.all(kundenDaten.filter(kunde => kunde).map(async (kunde) => {
      // Name verarbeiten (Vorname, Nachname)
      if (!isPrivat && (!kunde || !kunde.firmenName)) {
        console.warn('Ungültige Firmeninformationen:', kunde);
        problematischeEintraegeFirma.push(kunde);
        return null; // Überspringen, falls Firmenname fehlt
      }
      if (isPrivat && (!kunde || !kunde.firstName || !kunde.surName)) {
        console.warn('Ungültige Name-Informationen:', kunde);
        problematischeEintraegeName.push(kunde);
        return null; // Überspringen, falls Name unvollständig ist
      }


  
      let trimmedFirstName = null;
      let trimmedSurName = null;
      let nachnameArray = [];
      let vornameArray = [];
      let vorname = '';
      let nachname = '';
      if (isPrivat) {
        trimmedFirstName = (kunde.firstName || '').trim();
        trimmedSurName = (kunde.surName || '').trim();
  
        let nameArray = (trimmedFirstName + ' ' + trimmedSurName).split(' ').filter(part => part.length > 0);
        // Entfernen von Anreden
        nameArray = nameArray.filter(namePart => !anreden.includes(namePart.toLowerCase()));
        let nachnameGefunden = false;
  
        for (let i = 0; i < nameArray.length; i++) {
          let teil = nameArray[i].toLowerCase();
  
          if (bekannteNachnamenPraefixe.includes(teil)) {
            nachnameGefunden = true;
          }
  
          if (nachnameGefunden) {
            nachnameArray.push(nameArray[i]);
          } else {
            vornameArray.push(nameArray[i]);
          }
        }
  
        // Wenn kein Nachnamepräfix gefunden wurde oder der Nachname leer ist, den letzten Namen als Nachname betrachten
        if (nachnameArray.length === 0 && vornameArray.length > 0) {
          nachnameArray.push(vornameArray.pop());
        }
  
        // Wenn kein Nachnamepräfix im Feld Vorname gefunden wurde oder der Vorname leer ist, den letzten Namen als Nachname betrachten
        if (vornameArray.length === 0 && nachnameArray.length > 0) {
          vornameArray.push(nachnameArray.pop());
        }
  
        vorname = vornameArray.join(' ').trim();
        nachname = nachnameArray.join(' ').trim();
  
        kunde.firstName = vorname;
        kunde.surName = nachname;
  
        if (!vorname || !nachname) {
          problematischeEintraegeName.push(kunde);
          console.warn('Ungültige Name-Informationen:', kunde);
          return null; // Überspringen, falls Vorname oder Nachname fehlt
        }
      }
  
      // Adresse verarbeiten (Straße, Hausnummer)
      if (!kunde || !kunde.street || kunde.street === '' || kunde.street === ' ') {
        console.warn('Ungültige Straßeninformation:', kunde);
        problematischeEintraegeAnschrift.push(kunde);
        return null; // Überspringen, falls Straße fehlt
      }
      let strassenname = kunde.street || '';
  
      if (!kunde || !kunde.houseNumber || kunde.houseNumber === '' || kunde.houseNumber === ' ') {
        console.warn('Ungültige Hausnummer:', kunde);
        problematischeEintraegeAnschrift.push(kunde);
        return null; // Überspringen, falls Hausnummer fehlt
      }
      let hausnummer = kunde.houseNumber || '';
  
      // Reguläre Ausdrücke zur Erkennung von Straßen mit nummerierten Namen
      const strasseMitZahlenRegex = /(.*?\s\d{1,2}\..*)|(.*?\s\d{4}.*)|(.*?[A-Z]?\d{1,3}\s?.*)/;
  
      if (!strasseMitZahlenRegex.test(kunde.street)) {
        // Wenn die Straße keine spezielle Zahl enthält, versuchen, Straße und Hausnummer zu trennen
        let strasseMatch = kunde.street.match(/(.*?)(\d+\s*[a-zA-Z]?|\/\d+|\d+[a-zA-Z]?)$/);
        if (strasseMatch) {
          strassenname = strasseMatch[1].trim();
          hausnummer = strasseMatch[2].trim();
          erfolgreicheKorrekturen++; // Erfolgreich getrennte Einträge zählen
        } else {
          // Falls keine eindeutige Zuordnung möglich ist, Eintrag zu den problematischen hinzufügen
          if (!strassenname || !hausnummer) {
            problematischeEintraegeAnschrift.push(kunde);
            return null; // Überspringen, falls Straße oder Hausnummer fehlt
          }
        }
      } else {
        // Falls die Straße eine spezielle Zahl-Straße ist und keine übliche Hausnummer hat
        if (!strassenname || !hausnummer) {
          problematischeEintraegeAnschrift.push(kunde);
          return null; // Überspringen, falls Straße oder Hausnummer fehlt
        }
      }
  
      let land = kunde.country || {name: "Deutschland", code: "DE"};
  
      // Land hinzufügen (angenommen, dass es sich um Deutschland handelt)
      if (!kunde || !kunde.country) {
        console.warn('Ungültiges Land:', kunde);
        kunde.country = land;
      }
  
      // Rückgabe der neuen Datenstruktur
      if (isPrivat) {
        return {
          ...kunde,  // Behalte die bestehenden Kundeninformationen
          firstName: vorname,
          surName: nachname,
          street: strassenname,
          houseNumber: hausnummer,
          country: land,
        };
      } else {
        return {
          ...kunde,  // Behalte die bestehenden Kundeninformationen
          street: strassenname,
          houseNumber: hausnummer,
          country: land,
        };
      }
    }));
  
  // Entfernen Sie alle null-Werte aus den korrigierten Daten
  korrigierteDaten = korrigierteDaten.filter(kunde => kunde !== null);

  // Rückgabe des vollständigen Ergebnisobjekts
  return {
    korrigierteDaten: korrigierteDaten,
    erfolgreicheKorrekturen: erfolgreicheKorrekturen,
    problematischeEintraegeAnschrift: problematischeEintraegeAnschrift,
    problematischeEintraegeName: problematischeEintraegeName,
    problematischeEintraegeFirma: problematischeEintraegeFirma,
  };
};

export const validateAddress = async (adresse) => {
  try {
    // Verwendung der Positionstack API zur Adressvalidierung
    const apiKey = '99f592325f0ca15b25be1d37ac6ca0ad';
    const formattedAddress = `${adresse.street ? adresse.street : ''} ${adresse.houseNumber ? adresse.houseNumber : ''}, ${adresse.postCode ? adresse.postCode : ''} ${adresse.city ? adresse.city : ''}, ${adresse.country ? adresse.country : ''}`;
    const url = `http://api.positionstack.com/v1/forward?access_key=${apiKey}&query=${encodeURIComponent(formattedAddress)}`;

    const response = await fetch(url);
    const data = await response.json();

    if (data && data.data && data.data.length > 0) {
      return true;
    } else {
      console.warn('Adresse konnte nicht validiert werden:', adresse);
      return false;
    }
  } catch (error) {
    console.error('Fehler bei der Adressvalidierung:', error);
    return false;
  }
};
