<template>
  <Fieldset
    ref="generalRef"
    :toggleable="true"
    class="col-12"
    :collapsed="false"
    :pt="{
      legend: {
        class: 'p-0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: 'p-0 m-0 hidden' },
      toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: 'p-0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center gap-0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            generalRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Generelle Einstellungen</span>
      </div>
    </template>
    <div>
      <div class="flex justify-content-end pb-3">
        <Button
          label="Speichern"
          icon="pi pi-save text-xs"
          class="text-xs"
          outlined
          @click="saveForm" />
      </div>
      <div class="bg-blue-100">
        <i class="pi pi-info"></i>
        Derzeit Unterstüzt der Onlineshop B2B Kunden.

        <div
          v-if="extendedShopSettings.isOnlineShopActive"
          class="p-2 bg-blue-100">
          Die Shopseite ist über <br /><a
            :href="'https://shop.famtura.de/home/' + store.whoAmIData.slug"
            target="_blank"
            ><b>https://shop.famtura.de/home/{{ store.whoAmIData.slug }}</b></a
          >
          erreichbar.
          <br />
          staging:
          <a
            :href="
              'https://stagingcustomer.famtura.de/home/' + store.whoAmIData.slug
            "
            target="_blank"
            ><b
              >https://stagingcustomer.famtura.de/home/{{
                store.whoAmIData.slug
              }}</b
            ></a
          >
          <br />Denken Sie auch daran die Rechtstexte zu aktuallisieren.
        </div>
      </div>
      <Divider></Divider>
      <div class="grid pt-3">
        <div class="col-6">Onlineshop aktivieren:</div>
        <div class="col-6">
          <InputSwitch
            v-model="extendedShopSettings.isOnlineShopActive"
            @update:model-value="prepareOnlineShopActivation"
            :binary="true" />
        </div>
        <div class="col-6">
          selbständiges registrieren von B2B Kunden erlauben:
        </div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.allowRegister" />
        </div>
        <div class="col-6">Produkte ohne Bestand ausblenden:</div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.hideItemsWithoutStock" />
        </div>
        <div class="col-6">Neue Produkte in Hauptkategorie anzeigen:</div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.showNewProducts" />
        </div>
        <div class="col-6">Produkte mit Rabatt in Hauptkategorie anzeigen:</div>
        <div class="col-6">
          <InputSwitch v-model="extendedShopSettings.showDiscountProduct" />
        </div>
        <div class="col-6">
          Produkte als Neu markieren,bis maximale Tage nach Erstellung:
        </div>
        <div class="col-6">
          <InputNumber
            inputClass="w-full"
            v-if="
              (extendedShopSettings.maxDaysLeftForNewProduct =
                extendedShopSettings.maxDaysLeftForNewProduct || 5)
            "
            v-model="extendedShopSettings.maxDaysLeftForNewProduct"
            :show-buttons="true" />
        </div>
        <template
          v-if="
            extendedShopSettings?.homeSlider?.length == 1 &&
            extendedShopSettings?.homeSlider[0].homeImages == null
          ">
          <div class="col-6">Titel-Text für die Homeseite:</div>
          <div class="col-6">
            <InputText
              v-if="extendedShopSettings?.homeSlider?.[0]"
              class="w-full"
              v-model="extendedShopSettings.homeSlider[0].homePageTitel"
              v-tooltip="'Empfohlen max 10 Zeichen'" />
          </div>
          <div class="col-6">Unter-Titel-Text für die Homeseite:</div>
          <div class="col-6">
            <InputText
              v-if="extendedShopSettings?.homeSlider?.length == 1"
              v-model="extendedShopSettings.homeSlider[0].homePageSubTitel"
              class="w-full"
              v-tooltip="'Empfohlen max 35 Zeichen'" />
          </div>
        </template>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="laufTextRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: 'p-0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: 'p-0 m-0 hidden' },
      toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: 'p-0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center gap-0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            laufTextRef?.d_collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Info Lauftext</span>
      </div>
    </template>
    <div class="col-6">Lauftext Nachricht für die Homeseite:</div>
    <div class="col-6">
      <InputText v-model="extendedShopSettings.laufText" class="w-full" />
    </div>
    <div class="col-6">Anzeige-Dauer in Sekunden, des Lauftextes:</div>
    <div class="col-6">
      <InputNumber
        v-model="extendedShopSettings.laufTextDauer"
        :min="0"
        class="w-full" />
    </div>
  </Fieldset>
  <Fieldset
    ref="homeBilderSliderRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: 'p-0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: 'p-0 m-0 hidden' },
      toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: 'p-0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center gap-0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            homeBilderSliderRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Hauptseite Bild Slider</span>
      </div>
    </template>
    <div class="grid col-12">
      <div class="col-6">Bild/Bilder für Homeseite:</div>
      <div class="col-6">
        <FileUpload
          mode="basic"
          name="homeImages"
          url="/api/upload"
          accept="image/*"
          class="text-xs"
          :maxFileSize="2000000"
          customUpload
          @uploader="customBase64Uploader($event, 'homeImages', false)"
          :auto="true"
          chooseLabel="Bild Hochladen" />
      </div>
    </div>
    <div
      v-if="
        extendedShopSettings?.homeSlider?.length > 0 &&
        extendedShopSettings?.homeSlider[0].homeImages != null
      ">
      <div
        v-for="(element, index) in extendedShopSettings.homeSlider"
        :key="index"
        class="grid grid-nogutter pb-3">
        <div class="grid grid-nogutter col-10 pl-1">
          <div class="col-12">
            <InputText
              v-model="element.homePageTitel"
              placeholder="Haupttitel"
              class="w-full" />
          </div>
          <div class="col-12">
            <InputText
              v-model="element.homePageSubTitel"
              placeholder="Untertitel"
              class="w-full" />
          </div>
        </div>
        <div class="col-2">
          <Image
            preview
            :src="element.homeImages || require('@/assets/no-image.svg')"
            alt="Image"
            imageClass="h-6rem object-contain"
            width="50" />
          <Button
            icon="pi pi-trash"
            rounded
            size="small"
            text
            severity="danger"
            @click="deleteSlider(index)" />
        </div>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="itemSliderRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: 'p-0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: 'p-0 m-0 hidden' },
      toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: 'p-0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center gap-0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            itemSliderRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Artikel Slider</span>
      </div>
    </template>
    <div class="col">Autoplay Intervall in Sek.</div>
    <div class="col">
      <InputNumber
        v-model="extendedShopSettings.ItemAutoplayInterval"
        inputClass="text-xs" />
    </div>
    <div class="col">Artikel für Slider auswählen:</div>
    <div class="col">
      <div class="flex">
        <div class="flex-grow-1">
          <ItemInputSearch
            v-model="selectedItem"
            inputClass="text-xs w-full"
            filterItemsWithImage
            secondOptionClass="text-xs"
            optionClass="text-sm"
            ref="itemInputSearchRef" />
        </div>
        <div class="hidden md:block">
          <Button
            size="small"
            icon="pi pi-plus"
            outlined
            label="Hinzufügen"
            @click="addItemsToSlider" />
        </div>
        <div class="md:hidden">
          <Button
            size="small"
            icon="pi pi-plus"
            v-tooltip="'Hinzufügen'"
            outlined
            @click="addItemsToSlider" />
        </div>
      </div>
    </div>
    <div class="col"></div>
    <div class="col">
      <div
        v-for="(item, index) in extendedShopSettings.sliderItems"
        :key="index"
        class="py-2">
        <div class="flex grid gap-2 border-round border-solid">
          <div class="col-2">{{ item.EAN }}</div>
          <div class="col-6">{{ item.itemName }}</div>
          <div class="col-1">
            <Image
              :src="item.itemImageUrl"
              alt="Image"
              width="50"
              preview
              imageClass="max-w-4rem max-h-3rem object-contain" />
          </div>
          <div class="col-1">
            <Button
              severity="danger"
              text
              size="small"
              rounded
              icon="pi pi-trash"
              @click="deleteProductFromSlider(item)" />
          </div>
        </div>
      </div>
    </div>
  </Fieldset>
  <Fieldset
    ref="partnerLogoSliderRef"
    :toggleable="true"
    class="col-12"
    :collapsed="true"
    :pt="{
      legend: {
        class: 'p-0 m-0 bg-white border-none',
        style: { left: '-25px!important', position: 'relative' },
      },
      togglerIcon: { class: 'p-0 m-0 hidden' },
      toggleableContent: { class: 'p-0 m-0 bg-gray-100' },
      toggler: { class: 'py-0 my-0' },
      root: { class: 'p-0 my-0' },
    }">
    <template #legend>
      <div class="flex align-items-center gap-0 px-0 py-1">
        <i
          class="text-base mx-3"
          :class="
            partnerLogoSliderRef?.d_collapsed
              ? 'pi pi-chevron-down'
              : 'pi pi-chevron-up'
          "></i>
        <span class="text-base">Partner Logo Slider</span>
      </div>
    </template>
    <div class="col">Partnerlogo Slider</div>
    <div class="col">
      <LogoSlider @onConverted="handleConvertedImage" />
    </div>
    <div class="col"></div>
    <div class="col flex p-fluid">
      <div class="w-full">
        <VirtualScroller
          :items="extendedShopSettings.partnerLogos"
          :itemSize="160"
          showLoader
          :numToleratedItems="10"
          autoSize
          appendOnly
          class="border-1 surface-border border-round"
          style="height: 500px">
          <template v-slot:item="{ item }">
            <div class="grid">
              <div class="col-1">
                <Button
                  size="small"
                  severity="danger"
                  icon="pi pi-trash"
                  text
                  @click="deletePartnerlogo(item)"></Button>
              </div>
              <div class="col-11">
                <div>
                  {{ item.name }}
                </div>
                <div>
                  <img
                    :src="item?.image"
                    alt="Graustufenbild Partnerlogo"
                    class="p-card shadow-2 h-4rem" />
                </div>
              </div>
              <Divider />
            </div>
          </template>
        </VirtualScroller>
      </div>
    </div>
  </Fieldset>
</template>
<script setup>
import { inject, ref, reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { SettingsExtended } from "@/service/SettingsExtended";
import ItemInputSearch from "@/components/ItemInputSearch.vue";
import LogoSlider from "@/components/LogoSlider.vue";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import { compareAsc } from "date-fns";
import Divider from "primevue/divider";
const store = userStore();
const toast = useToast();
const settingsExtended = reactive({});
const selectedItem = ref(null);
const extendedShopSettings = inject("extendedShopSettings");

const itemSliderRef = ref();
const partnerLogoSliderRef = ref();
const homeBilderSliderRef = ref();
const generalRef = ref();
const laufTextRef = ref();
const deleteSlider = (index) => {
  if (extendedShopSettings.homeSlider.length == 1) {
    extendedShopSettings.homeSlider[0].homeImages = null;
  } else {
    extendedShopSettings.homeSlider.splice(index, 1);
  }
};

const deleteProductFromSlider = (item) => {
  const index = extendedShopSettings.sliderItems.indexOf(item);
  if (index > -1) {
    extendedShopSettings.sliderItems.splice(index, 1);
  }
};

const addItemsToSlider = async () => {
  const sliderItems = extendedShopSettings.sliderItems;

  const itemListShema = {
    item_id_company: 0,
    EAN: "",
    itemImageUrl: 1,
    itemName: "",
  };
  const newItem = JSON.parse(JSON.stringify(itemListShema));
  if (selectedItem.value && typeof selectedItem === "object") {
    if (!selectedItem.value.itemImageUrl[0]) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Produkt hat kein Bild",
        life: 3000,
      });
      return;
    }

    newItem.itemName = selectedItem.value.itemName;
    newItem.item_id_company = selectedItem.value.item_id_company;
    newItem.itemImageUrl = selectedItem.value.itemImageUrl[0].fileUrl.replace(
      "/auth",
      ""
    );
    newItem.EAN = selectedItem.value.EAN;
    //wenn item bereits vorhanden, dann entfernen
    const index = sliderItems.findIndex(
      (item) =>
        item.itemName === newItem.itemName &&
        item.item_id_company === newItem.item_id_company &&
        item.itemImageUrl === newItem.itemImageUrl &&
        item.EAN === newItem.EAN
    );
    if (index !== -1) {
      sliderItems.splice(index, 1);
    }
    //neues item hinzufügen
    sliderItems.push(newItem);
    selectedItem.value = null;
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Produkt hinzugefügt",
      life: 3000,
    });
  } else {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Bitte wählen Sie ein Produkt aus",
      life: 3000,
    });
    return;
  }
};

const customBase64Uploader = async (event, type, removebg) => {
  console.log("customBase64Uploader", type);
  const isLogo = type === "logo";
  const isStamp = type === "stamp";
  const isSignature = type === "signature";
  const isHomeImage = type === "homeImages";
  const file = event.files[0];
  //const file2 = event.files[0];
  const reader = new FileReader();
  reader.onloadend = async function () {
    const base64Data = reader.result;
    logoPreview.value = base64Data;

    if (isLogo) {
      // Bildauflösung auslesen
      const img = new Image();
      img.onload = function () {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        extendedShopSettings.guiSettings.companyLogoWidth = width;
        extendedShopSettings.guiSettings.companyLogoHeight = height;
        imageLogoHeight.value = height;
        imageLogoWidth.value = width;
        console.log(`Bildauflösung: ${width} x ${height}`);
      };
      img.src = base64Data;
    }

    const fileData = new extendedShopSettings();
    //const fileData2 = new extendedShopSettings();
    fileData.append("filedata", file);
    //fileData2.append('filedata', file);
    if (removebg == "removebg_stamp") fileData.append(removebg, true);
    if (removebg == "removebg_signature") fileData.append(removebg, true);
    try {
      const response = await fAxios.post("/company/" + type, fileData);
      let url2 =
        "https://storage.famtura.de/api/auth/files/upload/" +
        store.apiKeyStorage +
        "/" +
        store.cid;
      if (store.debug)
        console.log("🚀 ~ file: DocumentSettings.vue:3259 ~ url2:", url2);

      const response2 = await fAxios.post(url2, fileData);

      if (isStamp && response.status == 201) {
        extendedShopSettings.inputStampInLetters = true;
      }
      if (isSignature && response.status == 201) {
        extendedShopSettings.inputHandSignLetters = true;
      }
      console.log("islogo", isLogo);

      const parts = await response2.data.fileUrl.split("files/");
      const resultURL = parts[1];
      const publicUrl = "https://storage.famtura.de/api/files/" + resultURL;
      if (store.debug)
        console.log(
          "🚀 ~ file: DocumentSettings.vue:1695 ~ publicLogoUrl:",
          publicUrl
        );

      if (isLogo) extendedShopSettings.logoUrl = publicUrl;

      if (isHomeImage) {
        if (
          extendedShopSettings?.homeSlider &&
          extendedShopSettings?.homeSlider?.length > 0 &&
          extendedShopSettings?.homeSlider?.[0].homeImages != null
        ) {
          extendedShopSettings.homeSlider.push({
            homeImages: publicUrl,
            homePageTitel: "",
            homePageSubTitel: "",
          });
        } else {
          extendedShopSettings.homeSlider[0].homeImages = publicUrl;
        }
      }
      if (isStamp) stampImage.value = publicUrl;

      saveForm();
      onUpload();
      setTimeout(async () => {
        await loadImage();
        await loadImage("stamp");
        await loadImage("signature");
      }, 100);

      // Wenn du weitere Aktionen mit der Antwort durchführen möchtest, füge den Code hier hinzu.
    } catch (error) {
      console.error(error);
      //errorToast(toast, error);
    }

    werteLaden(); // Dies sollte außerhalb des try-catch-Blocks sein, wenn es nach dem Upload ausgeführt werden soll.
  };

  // Lade die Blob-Daten und starte den Konvertierungsprozess
  let blob = await fetch(file.objectURL).then((r) => r.blob());
  reader.readAsDataURL(blob);
  setTimeout(() => {
    adjustLogoSize();
    //checkAdjustToHeight();
    changeLogoImageClass();
    saveForm();
  }, 300);
};

const handleConvertedImage = (event) => {
  if (!extendedShopSettings?.partnerLogos) {
    extendedShopSettings.partnerLogos = [];
  }
  extendedShopSettings.partnerLogos.push({
    name: event.name,
    image: event.image,
  });
  console.log(extendedShopSettings.partnerLogos);
};

const deletePartnerlogo = (data) => {
  const index = extendedShopSettings.partnerLogos.indexOf(data);
  if (index > -1) {
    extendedShopSettings.partnerLogos.splice(index, 1);
  }
};

const prepareOnlineShopActivation = () => {
  if (extendedShopSettings.isOnlineShopActive == true) {
    checkOnlineShopInitialData();
  }
};

const checkOnlineShopInitialData = async () => {
  //wenn shippingKinds vorhanden ist sollte es bereits initialisiert sein
  await SettingsExtended.getSettingsExtended().then(async (response) => {
    if (response?.extendedShopSettings?.shippingKinds?.length > 0) {
      return;
    } else {
      await SettingsExtended.setSettingsExtended({ extendedShopSettings });
    }
  });
  console.log(
    "🚀 ~ file: DocumentSettings.vue:3504 ~ extendedShopSettings:",
    extendedShopSettings
  );
};

const saveForm = async () => {
  await SettingsExtended.setSettingsExtended({ extendedShopSettings })
    .then(async (res) => {
      setTimeout(() => {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Daten gespeichert",
          life: 3000,
        });
      }, 100);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {});
};
</script>
