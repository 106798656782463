import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const CalendarService = {
    async getData(timeStamp) {
        try {
            const store = userStore();
            const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/calentry-list");
            const calendars = await response.data || [];

            console.log("🚀 ~ file: CalendarService.js:11 ~ calendars:", calendars)

            store.dataStates.calendars = timeStamp;
            store.setDataToStorage("calendars", calendars?.calcEntries);
            return Promise.resolve(calendars?.calcEntries);
        } catch (e) {
            console.log(e);
        }
    },

    async checkForChanges() {
        const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/change-stats ");
        const data =
            console.log("🚀 ~ file: CalendarService.js:56 ~ data:", data)
        return Promise.resolve(data);
    },

    async createCalendarEntry(data) {
        try {
            const response = await iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/create-calentry", data);
            return Promise.resolve(response.data);
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    },

    async updateCalendarEntry(data) {
        try {
            const response = await iAxios.patch(process.env.VUE_APP_BASE_API_URL + "/company/calentry-edit", data);
            return Promise.resolve(response.data);
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    },

    async deleteCalendarEntry(data) {
        try {
            const response = await iAxios.delete(process.env.VUE_APP_BASE_API_URL + "/company/calentry-delete", data);
            return Promise.resolve(response.data);
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    },

    async getCalendarEntries() {
        const key = "calendar";
        const store = userStore();
        const states = await this.checkForChanges();
        const timestampBE = states?.calendars;
        const timestampFE = store?.dataStates?.calendars;

        if (states?.cid != store?.dataStates?.cid) {
            store.dataStates.cid = states?.cid;
            return Promise.resolve(this.getData(timestampBE));
        }

        const foragePassed = await store.forageCheckForKey(key);
        if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
            return Promise.resolve(this.getData(timestampBE));
        } else {
            return store.getDataFromStorage(key);
        }
    },
};
