<template>
  <Dialog
    v-model:visible="visible"
    modal
    class="custom-dialog"
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(2px)',
      },
    }">
    <div class="flex justify-content-center text-2xl mb-5">
      Ihre Testzeit läuft in
      <span class="text-red-200 font-bold px-2"> {{ demoDaysLeft }} Tagen</span>
      aus.
    </div>

    <PricingPlanShort v-if="!showDetails" @show-details="showDetails=true"/>
    <PricingPlanCompare v-else />

    <Button
      :label="showDetails ? 'Buchen' : 'Vergleichen'"
      @click="showDetails = !showDetails" />
  </Dialog>
</template>

<script setup>
import { ref } from "vue";
import PricingPlanShort from "./PricingPlanShort.vue";
import PricingPlanCompare from "./PricingPlanCompare.vue";
const visible = ref(true);
const demoDaysLeft = ref(12);
const showDetails = ref(false);
</script>

<style scoped>
.custom-dialog .p-dialog-content {
  height: calc(100vh - 5rem) !important; /* Maximiert den Dialoginhalt */
}

.custom-dialog .p-dialog {
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
}

/* Container */
.pricing-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem;
}

/* Cards */
.pricing-card {
  background-color: #545252ff;
  color: #ffffff;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 250px;
}

.pricing-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Highlighted card */
.pricing-card-highlighted {
  margin-top: 35px;
  background-color: #000000;
  transform: scale(1.25);
  box-shadow: 0 20px 15px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

/* Badge */
.badge {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #00bfa5;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
}

/* Titles */
.pricing-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.pricing-brief {
  font-size: 1rem;
  color: #bbbbbb;
  margin-bottom: 1rem;
}

/* Price */
.pricing-price {
  font-weight: bold;
  margin-bottom: 1rem;
}

.price {
  margin: 0;
  padding: 0.25em 0;
  background: #312c2cff;
  font-size: 200%;
  color: white;
  margin-bottom: 1rem;
}

/* Features */
.pricing-features {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
}

.pricing-features li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: #dddddd;
}

/* Button */
.pricing-button {
  background-color: #00bfa5;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pricing-button:hover {
  background-color: #00897b;
}
</style>
