<template>
    <template v-if="isMainUser">
      <div v-if="store.whoAmIData.status == 'ACTIVE'">
        <div class="p-card w-full p-3 mt-3">
          <div class="w-full">
            Ihr Ausgewählter Tarif <b>{{ paymentData?.plan }}</b>
          </div>
          <Button class="my-3" :label="showDetails ? 'Details schließen' : 'Zeige Tarif details'" @click="showHideDetails()" />
  
          <table class="w-full" v-if="showDetails">
            <thead>
              <tr style="background: #f9fafb;">
                <th class="p-3 text-left"></th>
                <th class="p-3 text-center rounded-lg text-lg" :class="paymentData?.plan === 'BASIC' ? 'bg-yellow-500' : ''">Basic</th>
                <th class="p-3 text-center rounded-lg text-lg" :class="paymentData?.plan === 'PREMIUM' ? 'bg-yellow-500' : ''">Premium</th>
                <th class="p-3 text-center rounded-lg text-lg" :class="paymentData?.plan === 'BUSINESS' ? 'bg-yellow-500' : ''">Business</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in pricingPlanDetails" :key="index">
                <td class="p-3">{{ row.feature }}</td>
                <td class="p-3 text-center" :class="paymentData?.plan === 'BASIC' ? 'border-yellow-500' : ''">
                  <template v-if="typeof row.plans.Basic === 'boolean'">
                    <i :class="row.plans.Basic ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                  </template>
                  <template v-else>{{ row.plans.Basic }} <small v-if="row.plans?.SubBasic"><br>{{ row.plans.SubBasic }} </small></template>
                </td>
                <td class="p-3 text-center" :class="paymentData?.plan === 'PREMIUM' ? 'border-yellow-500' : ''">
                  <template v-if="typeof row.plans.Premium === 'boolean'">
                    <i :class="row.plans.Premium ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                  </template>
                  <template v-else>{{ row.plans.Premium }}<small v-if="row.plans?.SubPremium"><br>{{ row.plans.SubPremium }} </small></template>
                </td>
                <td class="p-3 text-center" :class="paymentData?.plan === 'BUSINESS' ? 'border-yellow-500' : ''">
                  <template v-if="typeof row.plans.Business === 'boolean'">
                    <i :class="row.plans.Business ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                  </template>
                  <template v-else>{{ row.plans.Business }}<small v-if="row.plans?.SubBusiness"><br>{{ row.plans.SubBusiness }} </small></template>
                </td>
              </tr>
              <tr>
                <td></td>
                <td class="p-3 text-center"><template v-if="paymentData?.plan !== 'BASIC'"><Button label="Tarif downgraden"  @click="switchPlan('BASIC')"></Button></template></td>
                <td class="p-3 text-center">
                  <template v-if="paymentData?.plan === 'BASIC'"><Button label="Tarif upgraden" @click="switchPlan('PREMIUM')"></Button></template>
                  <template v-else-if="paymentData?.plan === 'BUSINESS'"><Button label="Tarif downgraden" @click="switchPlan('PREMIUM')"></Button></template>
                </td>
                <td class="p-3 text-center"><template v-if="paymentData?.plan !== 'BUSINESS'"><Button label="Tarif upgraden" @click="switchPlan('BUSINESS')"></Button></template></td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div class="p-card w-full p-3 mt-3">
          <Button label="Vertrag Kündigen" @click="confirmPlanCancellation"/>
        </div>
  
        <ConfirmDialog group="cancelPlan">
        <template #container="{ message, acceptCallback, rejectCallback }">
          <div
            class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div
              class="border-circle bg-red-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
              <i class="pi pi-trash text-5xl text-white"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{
              message.header
            }}</span>
            <p class="mb-0">
              Möchten Sie wirklich ihren Tarif kündigen?
            </p>
            <p>
              Geben Sie aus Sicheheitsgründen das Wort "KÜNDIGUNG" in Großbuchstaben und die Pin 
              <span class="font-bold">{{ confirmPin }}</span> ein.
            </p>
            Bestätigung: <InputText v-model="confirmCancelWord" class="w-12rem" />
            <p></p>
            Pin:
            <div class="card flex justify-content-center">
              <InputOtp v-model="confirmedPin" integerOnly />
            </div>
            <div class="flex align-items-center gap-2 mt-4">
              <Button
                label="Ja"
                outlined
                severity="danger"
                @click="acceptCallback"
                class="w-8rem"></Button>
              <Button
                label="Nein"
                outlined
                @click="rejectCallback"
                class="w-8rem"></Button>
            </div>
          </div>
        </template>
      </ConfirmDialog>
      </div>
      <div v-else>      
        <div v-if="paymentData?.paymentStatus === 'open' && paymentData?.paymentLinks.checkout.status == 'open'">
          <div class="p-card w-full p-3 mt-3 justify-content-center">
            <h2>
              Ihre Zahlung wird verarbeitet, bitte haben Sie etwas Geduld.
              <br />
              <Button class="mt-3" @click="goToCheckout" label="Hier gelangen Sie zum Bezahlvorgang"/>
            </h2>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-content-center text-2xl mb-5">
            <template v-if="store.whoAmIData.availableTrialDays > 0">
              Ihre Testzeit läuft in
              <span class="text-red-200 font-bold px-2"> {{ store.whoAmIData.availableTrialDays }} Tagen</span>
              aus.
            </template>
            <template v-else>
              <span class="text-red-200 font-bold px-2">Ihre Testzeit ist abgelaufen.</span>
            </template>
          </div>
          
          <PricingPlanShort v-if="!showDetails" @show-details="showDetails=true"/>
          <PricingPlanCompare v-else />
          
          <Button
          :label="showDetails ? 'Buchen' : 'Vergleichen'"
          class="w-full p-button-lg mt-7"
          @click="showDetails = !showDetails" />
        </div>
  
        <div class="p-card w-full p-3 mt-3" v-if="payments.length > 0">
          <h2>Zahlungshistorie</h2>
          <DataTable :value="payments" tableStyle="min-width: 50rem">
            <Column field="date" header="Fälligkeit">
              <template #body="{ data }">
                {{ formatDatum(new Date(data.date)) }}
              </template>
            </Column>
            <Column field="amountValue" header="Betrag"></Column>
            <Column field="description" header="Beschreibung"></Column>
            <Column field="status" header="Status">
              <template #body="{ data }">
                {{ data.status === 'paid' ? 'Bezahlt' : data.status }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </template>
  </template>
  
  <script setup>
  import { onMounted, ref } from "vue";
  import PricingPlanShort from "./PricingPlanShort.vue";
  import PricingPlanCompare from "./PricingPlanCompare.vue";
  import { userStore } from "@/store/userStore";
  import { storeToRefs } from "pinia";
  import iAxios from "@/store/axiosInterface";
  import { formatDate, formatDatum } from "@/utils/formatUtils";
  import { useToast } from "primevue/usetoast";
  import { useConfirm } from "primevue/useconfirm";
  const showDetails = ref(false);
  const store = userStore();
  const {
    isMainUser,
  } = storeToRefs(store);
  const paymentData = ref();
  const payments = ref([]);
  const pricingPlanDetails = ref();
  const nextPaymentData = ref();
  const confirmPin = ref();
  const confirmedPin = ref();
  const confirmCancelWord = ref();
  const toast = useToast();
  const confirm = useConfirm();
  
  const goToCheckout = () => {
    let checkoutURL = paymentData.value.paymentLinks.checkout.href;
    
    document.location.href = checkoutURL;
  }
  
  function generatePin() {
    return Math.floor(Math.random() * 9000) + 1000;
  }
  
  const switchPlan = (planIdentifier) => {
    confirm.require({
          message: 'Sie sind dabei in einen neuen Tarif zu wechseln. Sind Sie sicher?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          rejectClass: 'p-button-secondary p-button-outlined',
          rejectLabel: 'Abbrechen',
          acceptClass: 'p-button-danger',
          acceptLabel: 'Ja',
          accept: () => {
            iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/plan-upgrade/" + planIdentifier)
            .then(async (res) => {
              console.log(res);
              if (res.status == 200) {
                toast.add({
                  severity: "info",
                  summary:
                    "Tarif umgeändert",
                  detail: "Ihr Tarif wurde erfolgreich umgeändert.",
                  life: 3000,
                });
                let response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/payment-comeback");
                paymentData.value = response.data;

                store.whoAmIData = await store.whoAmI();
                console.log(store.whoAmIData);
              }
            });
          },
          reject: () => {
              toast.add({ severity: 'error', summary: 'Tarifwechsel abgebrochen', detail: 'Sie haben den Tarifwechsel abgebrochen.', life: 3000 });
          }
      });
  }
  
  const confirmPlanCancellation = () => {
    confirmPin.value = generatePin();
    confirm.require({
      group: "cancelPlan",
      header: "Tarif kündigung bestätigen",
      icon: "pi pi-trash text-red-500",
      acceptClass: "p-button-danger",
      acceptLabel: "Ja",
      rejectLabel: "Nein",
      defaultFocus: "reject",
      accept: () => {
        const pinCorrect = confirmPin.value == confirmedPin.value;
        const cancelConfirmation = confirmCancelWord.value === "KÜNDIGUNG";
        if (pinCorrect && cancelConfirmation) {
          iAxios
            .get(process.env.VUE_APP_BASE_API_URL +"/company/payment-quit")
            .then(async (res) => {
              console.log(res);
              if (res.status == 200) {
                toast.add({
                  severity: "info",
                  summary:
                    "Der Vertrag wurde gekündigt",
                  detail: "Vertrag gekündigt",
                  life: 3000,
                });
                store.whoAmIData.status = 'TRIAL';
                store.whoAmIData = await store.whoAmI();
              }
            });
  
        } else {
          confirmCancelWord.value = "";
          confirmedPin.value = "";
          toast.add({
            severity: "error",
            summary:
              "Fehler beim Kündigen ihres Tarifs",
            detail:
              (!pinCorrect ? " Pin fehlerhaft" : "") +
              (!cancelConfirmation ? " Kündigungsbestätigung Falsch" : ""),
            life: 3000,
          });
        }
        confirmedPin.value = "";
        confirmCancelWord.value = "";
      },
      reject: () => {
        confirmedPin.value = "";
        confirmCancelWord.value = "";
        toast.add({
          severity: "error",
          summary: "Abgebrochen",
          detail: "Tarif Kündigung abgebrochen",
          life: 3000,
        });
      },
    });
  };
  
  const showHideDetails = () => {
    showDetails.value = !showDetails.value;
  }
  
  onMounted(async () => {
    let response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/plans");
    pricingPlanDetails.value = response.data.pricingPlanDetails;
  
    response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/payment-comeback");
    paymentData.value = response.data;
    
    console.log(paymentData.value);
    if (store.whoAmIData.status !== 'ACTIVE') {
      return;
    }
    let paymentDates = Object.keys(paymentData?.value?.history);
    if (paymentDates.length == 0) {
      return;
    }
    paymentDates.forEach(date => {
      paymentData.value.history[date].date = date;
      paymentData.value.history[date].amountValue = paymentData.value.history[date].amount.value;
      paymentData.value.history[date].amountCurrency = paymentData.value.history[date].amount.currency;
      payments.value.push(paymentData.value.history[date]);
    });
    let lastPayment = paymentDates[paymentDates.length - 1];
    let lastPaymentDate = new Date(lastPayment);
    lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 1);
    nextPaymentData.value = lastPaymentDate;
  
    console.log(paymentData)
  });
  
  </script>
  
  <style scoped>
  th,
  td {
    border-bottom: 1px solid #e5e5e5;
  }
  </style>