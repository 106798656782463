import iAxios from "@/store/axiosInterface";
export const getTickets = (data) => {
  return data;
};

export const SettingsExtended = {
  async getData() {
    const url="/company/settings-extended";
    const response = await iAxios.get(url);
    const data = await response.data;
    return Promise.resolve(data);
  },

  async setSettingsExtended(data) {
    const url="/company/settings-extended";
    const response = await iAxios.put(url,data);
    return Promise.resolve(response);
  },

  async getSettingsExtended() {
    return Promise.resolve(this.getData());
  },

};
