<template>
    <div class="surface-0 p-4">
      <div class="text-center mb-5">
        <h1 class="text-3xl font-bold text-900 mb-3">Vergleich Detailiert</h1>
        <p class="text-600">Vergleich der Pakete</p>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full border-separate border-spacing-y-4">
          <thead>
            <tr>
              <th class="p-3 text-left"></th>
              <th class="p-3 text-center bg-gray-100 rounded-lg text-lg">Basic</th>
              <th class="p-3 text-center bg-gray-100 rounded-lg text-lg">Premium</th>
              <th class="p-3 text-center bg-gray-100 rounded-lg text-lg">Business</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in pricingPlanDetails" :key="index">
              <td class="p-3">{{ row.feature }}</td>
              <td class="p-3 text-center">
                <template v-if="typeof row.plans.Basic === 'boolean'">
                  <i :class="row.plans.Basic ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                </template>
                <template v-else>{{ row.plans.Basic }} <small v-if="row.plans?.SubBasic"><br>{{ row.plans.SubBasic }} </small></template>
              </td>
              <td class="p-3 text-center">
                <template v-if="typeof row.plans.Premium === 'boolean'">
                  <i :class="row.plans.Premium ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                </template>
                <template v-else>{{ row.plans.Premium }}<small v-if="row.plans?.SubPremium"><br>{{ row.plans.SubPremium }} </small></template>
              </td>
              <td class="p-3 text-center">
                <template v-if="typeof row.plans.Business === 'boolean'">
                  <i :class="row.plans.Business ? 'pi pi-check text-green-500' : 'pi pi-times text-gray-500'"></i>
                </template>
                <template v-else>{{ row.plans.Business }}<small v-if="row.plans?.SubBusiness"><br>{{ row.plans.SubBusiness }} </small></template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  
<script setup>
import iAxios from '@/store/axiosInterface';
import { ref } from 'vue';
import { onMounted } from 'vue';

onMounted(async () => {
  const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/plans");
  pricingPlanDetails.value = response.data.pricingPlanDetails;
  console.log(response);
});
const pricingPlanDetails = ref();
</script>


<style scoped>
table {
  border-spacing: 0 10px;
}
th,
td {
  border-bottom: 1px solid #e5e5e5;
}
</style>
