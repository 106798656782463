<template>
  <div>
    <div class="w-full text-xl font-semibold">
      <div class="my-4">
        {{ paymentData?.paymentDescription }}
      </div>
      <div>
        <DataTable :value="payments" tableStyle="min-width: 50rem">
          <Column field="date" header="Fälligkeit">
            <template #body="{ data }">
              {{ formatDatum(new Date(data.date)) }}
            </template>
          </Column>
          <Column field="amountValue" header="Betrag">
            <template #body="{ data }">{{ data.amountValue }} €</template>
          </Column>
          <Column field="description" header="Beschreibung">
            <template #body="{ data }">
              {{ data.description.split("_")[2] }}
            </template>
          </Column>
          <Column field="status" header="Status">
            <template #body="{ data }">
              {{ getStatus(data.status) }}
            </template>
          </Column>
        </DataTable>
      </div>
      <div v-if="nextPaymentData" class="mt-3">
        Die nächste Zahlung ist am <b>{{ formatDatum(nextPaymentData) }}</b>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import iAxios from "@/store/axiosInterface";
import { formatDatum } from "@/utils/formatUtils";
const store = userStore();
const paymentData = ref();
const payments = ref([]);
const pricingPlanDetails = ref();
const nextPaymentData = ref();

onMounted(async () => {
  let response = await iAxios.get("/company/plans");
  pricingPlanDetails.value = response.data.pricingPlanDetails;

  response = await iAxios.get("/company/payment-comeback");
  paymentData.value = response.data;

  let paymentDates = Object.keys(paymentData?.value?.history);
  if (paymentDates.length == 0) {
    return;
  }
  paymentDates.forEach((date) => {
    paymentData.value.history[date].date = date;
    paymentData.value.history[date].amountValue =
      paymentData.value.history[date].amount.value;
    paymentData.value.history[date].amountCurrency =
      paymentData.value.history[date].amount.currency;
    payments.value.push(paymentData.value.history[date]);
  });
  let lastPayment = paymentDates[paymentDates.length - 1];
  let lastPaymentDate = new Date(lastPayment);
  lastPaymentDate.setMonth(lastPaymentDate.getMonth() + 1);
  nextPaymentData.value = lastPaymentDate;

  console.log(pricingPlanDetails.value);
});

const getStatus = (status) => {
  switch (status) {
    case "paid": return "Bezahlt";
    case "cancelled": return "Gekündigt";
  }
  return status;
}
</script>
