<template>
  <InputText
    v-model="formData.paymentModuleApiKey"
    placeholder="paymentModuleApiKey" />
  <div class="flex align-itemscenter mt-3">
    <label for="paymentModuleEnabled"> paymentModuleEnabled</label>
    <InputSwitch
      id="paymentModuleEnabled"
      v-model="formData.paymentModuleEnabled"
      :binary="true" />
  </div>
  <div>
    <Button label="Speichern" @click="saveForm" />
  </div>
</template>

<script setup>
import {  reactive,onBeforeMount, onMounted } from "vue";
import { userStore } from "@/store/userStore";
import iAxios from '@/store/axiosInterface';
import { useToast } from "primevue/usetoast";
const store = userStore();
const toast=useToast();

const formData=reactive({});

const saveForm = () => {
  const url = process.env.VUE_APP_BASE_API_URL + "/company/settings";
  const res = iAxios
    .put(url, formData)
    .then(async (res) => {
      setTimeout(() => {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Daten gespeichert",
          life: 3000,
        });
        store.getCompanySettings;
      }, 100);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {});
};

onMounted(async () => {
    Object.assign(formData,store.companySettings);
});
</script>
