<template>
  <div class="pricing-container" v-if="isMainUser">
    <!--class="bg-gray-700 text-white text-center pt-6 pb-6 border-round-lg shadow-lg transition-transform duration-300 w-2 hover:shadow-xl hover:scale-105"-->
    <div
      v-for="(plan, index) in pricingPlans"
      :key="index"
      class="pricing-card"
      :class="{ 'pricing-card-highlighted': plan.recommended }">
      <div v-if="plan.recommended" class="badge bg-teal-500">Empfohlen</div>
      <span class="text-sm md:text-xl font-bold">{{ plan.name }}</span>
      <p class="text-xs md:text-lg pricing-brief">{{ plan.brief }}</p>

      <div v-if="!plan.discounted" class="pricing-price price">
        {{ plan.defaultPrice }}
      </div>
      <div v-else>
        <div class="text-xs md:text-lg text-red-400 line-through ">{{ plan.defaultPrice }}</div>
        <div class="text-xl md:text-4xl pricing-price price">{{ plan.discountedPrice }}</div>
      </div>

      <ul class="text-xs md:text-sm list-none p-0 m-0 mb-3">
        <li v-for="(feature, idx) in plan.features" :key="idx" class="mb-2">
          <i class="text-xs md:text-sm pi pi-check-circle text-green-500 mr-0 md:mr-2"></i> {{ feature }}
        </li>
        <li>
          <i class="text-xs md:text-sm  pi pi-check-circle text-green-500 mr-0 md:mr-2"></i> <a @click="showDetails()" class="cursor-pointer">und mehr...</a>
        </li>
      </ul>
      <Button rounded class="text-xs md:text-sm  bg-teal-500 hover:bg-teal-700 text-white cursor-pointer transition-colors duration-300 px-6 py-2" @click="buyNow(plan)">Jetzt Kaufen</Button>
    </div>
  </div>
</template>

<script setup>
import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";

const store = userStore();
const {
  isMainUser,
} = storeToRefs(store);

const toast = useToast();
const visible = ref(true);
const emit=defineEmits(["show-details"]);
const showDetails = () => {
  emit("show-details");
};

onMounted(async () => {
  let response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/plans");
  pricingPlans.value = response.data.pricingPlans;
});
const pricingPlans = ref();

const buyNow = async (plan) => {
  iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/payment-init/" + plan.name.toUpperCase()).then(res => {
    window.location.replace(res.data.payment_url);
  }).catch(() => {
    toast.add({
      severity: "error",
      summary: "Fehler beim Bezahlvorgang",
      detail: "Es ist ein Fehler beim Bezahlvorgang aufgetreten.",
    });
  });
}
</script>

<style scoped>
.custom-dialog .p-dialog-content {
  height: calc(100vh - 5rem) !important; /* Maximiert den Dialoginhalt */
}

.custom-dialog .p-dialog {
  width: 100vw !important;
  height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
}

/* Container */
.pricing-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  padding: 2rem;
}

/* Cards */
.pricing-card {
  background-color: #545252ff;
  color: #ffffff;
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 250px;
}

.pricing-card:hover {
  transform: translateY(-20px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

/* Highlighted card */
.pricing-card-highlighted {
  margin-top: 10px;
  background-color: #000000;
  transform: scale(1.25);
  box-shadow: 0 20px 15px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

/* Badge */
.badge {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-weight: bold;
}

/* Titles */
.pricing-title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.pricing-brief {
  color: #bbbbbb;
  margin-bottom: 1rem;
}

/* Price */
.pricing-price {
  font-weight: bold;
  margin-bottom: 1rem;
}

.price {
  margin: 0;
  padding: 0.25em 0;
  background: #312c2cff;
  font-size: 200%;
  color: white;
  margin-bottom: 1rem;
}
</style>
