import { v4 as uuidv4 } from "uuid";

export const shippingMethods = [
    {
        id: uuidv4(),
        deletable: false,
        name: "Standardversand",
        publicName: "Standardversand",
        type: "flat_rate",
        details: {
            basePrice: 5.99, // Einheitlicher Preis für flatrate
            freeShipping: {
                enabled: true,
                minAmountForFree: 150,
            },
            shippingTime:"1-3 Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: false,
        name: "Lieferung Frei Haus",
        publicName: "Lieferung Frei Haus",
        type: "deliver_free",
        details: {
            basePrice: 0.0, // Einheitlicher Preis für flatrate
            freeShipping: null,
            shippingTime:"1-3 Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: false,
        name: "Lieferung",
        publicName: "Lieferung",
        type: "deliver_flat_rate",
        details: {
            basePrice: 79.99, // Einheitlicher Preis für flatrate
            freeShipping: {
                enabled: true,
                minAmountForFree: 300,
            },
            shippingTime:"1-3 Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: true,
        name: "Expressversand",
        publicName: "Expressversand",
        type: "express",
        details: {
            basePrice: 15.99, // Einheitlicher Preis für Expressversand
            freeShipping: null,
            shippingTime:"24h innerhalb Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: true,
        name: "Gewichtsbasierter Versand",
        publicName: "Gewichtsbasierter Versand",
        type: "weight_based",
        details: {
            basePrice: null, // Basispreis wird dynamisch durch Regeln bestimmt
            weightRules: [
                { maxWeight: 10, price: 5.0 },
                { maxWeight: 20, price: 10.0 },
                { maxWeight: 30, price: 15.0 },
            ],
            overWeight: {
                basePrice: 15.0, // Basispreis für Übergewicht
                additionalPricePerKg: 1.0, // Preis pro Kilogramm über der höchsten Regel
            },
            freeShipping: {
                enabled: true,
                minAmountForFree: 200,
            },
            preDefinedWeightValue: 400,
            denyShippingWithoutWeight: true,
            shippingTime:"2-4 Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: true,
        name: "Zonenbasierter Lieferung",
        publicName: "Zonenbasierter Lieferung",
        type: "zone_based",
        details: {
            basePrice: null, // Basispreis hängt von der Zone ab
            zones: [
                {
                    distance: 10,
                    price: 10.0, // Preis für diese Zone
                    freeShipping: {
                        enabled: true,
                        minAmountForFree: 100, // Kostenlos ab diesem Betrag
                    },
                },
                {
                    distance: 30,
                    price: 20.0, // Preis für diese Zone
                    freeShipping: {
                        enabled: true,
                        minAmountForFree: 150, // Kostenlos ab diesem Betrag
                    },
                },
            ],
            shippingTime:"1-2 Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: true,
        name: "Abholung vor Ort",
        publicName: "Abholung vor Ort",
        type: "pickup",
        details: {
            basePrice: 0.0, // Immer kostenlos
            freeShipping: null,
            shippingTime:"1-2 Werktage",
        },
        available: true,
        public: true,
    },
    {
        id: uuidv4(),
        deletable: true,
        name: "Sperrgutzuschlag",
        publicName: "Sperrgutzuschlag",
        type: "oversized",
        details: {
            basePrice: 10.0, // Fixer Zuschlag
            conditions: [
                { maxDimension: "150cm", price: 10.0 }, // Beispiel für Sperrgutlogik
            ],
            freeShipping: null,
            shippingTime:"3-7 Werktage",
        },
        available: true, // Nicht aktiv
        public: true,
    },
/*     {
        id: uuidv4(),
        deletable: true,
        name: 'Live-Rate Versand',
        publicName: "Echtzeit Versandpreise",
        type: 'live_rate',
        details: {
            provider: 'Echtzeitdaten von Versanddienstleistern',
            apiKey: '',
            selectedProvider: '',
            providers: [
                { label: 'DHL', value: 'dhl' },
                { label: 'UPS', value: 'ups' },
                { label: 'FedEx', value: 'fedex' },
                { label: 'SendCloud', value: 'sendcloud' },
                { label: 'USPS', value: 'usps' },
                { label: 'Shippo', value: 'shippo' }
            ],
            shippingTime:"1-3 Werktage",
        },
        available: true,
        public: true,
    }, */
];

