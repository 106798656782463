<template>
  <div>
    <TabView>
      <TabPanel header="Allgemein">
        <DocumentSettings />
      </TabPanel>
      <TabPanel header="Online Shop" v-if="isMainUser || isManagementUser">
        <ShopSettings />
      </TabPanel>
      <TabPanel header="Versandeinstellungen">
        <VersandSettings />
      </TabPanel>
      <TabPanel
        header="Shopify"
        v-if="store?.whoAmIData?.rules?.activatedModules?.shopify">
        <ShopifySettingsView />
      </TabPanel>
      <TabPanel v-if="store.debug" header="DB-Tools">
        <DbTools />
      </TabPanel>
      <TabPanel header="Zahlungsmodul" v-if="store.debug">
        <MolliePayment />
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import ShopSettings from "@/components/ShopSettings.vue";
import DocumentSettings from "./DocumentSettings.vue";
import MolliePayment from "./MolliePayment.vue";
import ShopifySettingsView from "./ShopifySettingsView.vue";
import DbTools from "./DbTools.vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import { onMounted, reactive, defineAsyncComponent, ref, provide } from "vue";
import { SettingsExtended } from "@/service/SettingsExtended";
import VersandSettings from "./VersandSettings.vue";
import {shippingMethods} from "@/utils/shippingUtils.js"
/* const VersandSettings = defineAsyncComponent(() =>
  import("./VersandSettings.vue")
); */
const store = userStore();
const { isMainUser,isManagementUser } = storeToRefs(store);

const extendedShopSettings = reactive({
  isOnlineShopActive: false,
  homeSlider: [
    {
      homePageTitel: "BOAHH!",
      homePageSubTitel: "Ein Erlebnis das Niemand vergisst!",
      homeImages: null,
    },
  ],
  ItemAutoplayInterval: 0,
  sliderItems: [],
  hideItemsWithoutStock: false,
  laufText: "",
  laufTextDauer: 0,
  partnerLogos: [
    {
      name: "",
      image: null,
    },
  ],
  shippingKinds:[...shippingMethods],
  defaultShippingType:"flat_rate",
  freeShipping:{
    enabled:false,
    minAmountForFree:150,
    type:"",
  }
});
provide("extendedShopSettings", extendedShopSettings);

const werteLaden = async () => {
  await SettingsExtended.getSettingsExtended().then((response) => {
    if (!response) {
      return;
    }
    Object.assign(extendedShopSettings, response?.extendedShopSettings);
  });
};



onMounted(async () => {
  await werteLaden();
});
</script>
