<template>
  <div>
    <div class="flex justify-end pb-3">
      <Button
        label="Hauptkategorie hinzufügen"
        icon="pi pi-plus"
        @click="addCategory"
        class="mr-2 text-sm" />
      <Button
        outlined
        label="Unterkategorie hinzufügen"
        icon="pi pi-plus"
        @click="addSubCategory"
        class="p-button-secondary mr-2 text-sm"
        :disabled="Boolean(!selectedKey)" />
      <Button
        label="Kategorie löschen"
        icon="pi pi-trash"
        @click="deleteAbleCategory"
        severity="danger"
        class="text-sm"
        outlined />
    </div>
    <Divider />

    <div class="grid">
      <div class="col-6">
        <span class="font-semibold">Kategorie Auswahl</span>
        <Tree
          v-model:selectionKeys="selectedKey"
          :value="categories"
          selectionMode="single"
          v-model:expandedKeys="expandedKeys"
          class="w-full"
          @nodeSelect="onCategorySelect"
          :highlightOnSelect="true">
          <template #default="slotProps">
            <span
              v-if="
                slotProps.node.label ==
                dialogRef?.data?.item?.category?.selectedLabel
              ">
              <b class="text-green-500">{{ slotProps.node.label }}</b>
            </span>
            <span v-else>
              {{ slotProps.node.label }}
            </span>
          </template>
        </Tree>
      </div>
      <div class="p-card col-6 pt-2 mt-4">
        <div class="p-grid">

          <div v-if="selectedKey.key!=null">
          <div v-if="selectedKey.key==defaultCategoryKey" class="col-12">
            <Button label="Standard Kategorie" disabled size="small" class="text-xs" text severity="primary"/>
          </div>
          <div v-else class="col-12">
            <Button label="Als Standard auswählen" size="small" class="text-xs" outlined severity="info" @click="markAsDefaultCategory(selectedKey.key)"/>
          </div>
        </div>
          <div class="p-col-12" v-if="selectedKey">
            <label class="fieldName" for="categoryName"
              >Kategorie Bezeichnung</label
            >
            <InputText
              id="categoryName"
              v-model="categoryName"
              placeholder="Kategorie Name"
              class="w-full"
              :disabled="Boolean(!selectedKey.label)"
              @blur="dontLetBlanc" />
              <Button label="Änderung Übernehmen" :disabled="categoryName=='' ||  selectedKey.label == categoryName" outlined class="text-xs" @click="changeCategoryName()"/>
            <Divider />
            <div class="p-2 gap-2">
              <Tag
                v-if="selectedSubCategory"
                value="Unterkategorie"
                severity="warning"
                class="ml-2" />
              <Tag
                v-if="
                  selectedMainCategory &&
                  selectedKey.label &&
                  !selectedSubCategory
                "
                value="Hauptkategorie"
                severity="success"
                class="ml-2" />
              <div class="pt-2">Auswahl:</div>
              <div class="text-base py-2">
                <div
                  v-if="selectedCategory.path"
                  class="bg-gray-200 py-2 border-round">
                  {{ selectedCategory.path }}
                </div>
              </div>
              <div
                v-if="
                  selectedMainCategory &&
                  selectedKey.label &&
                  !selectedSubCategory
                ">
                <div>
                  <!--Bildauswahl für Neue Produkte und Rabatierte Produkte-->
                  <div v-if="store.companySettings?.showNewProducts" class="p-card p-2 m-1">
                    <FileUpload
                      mode="basic"
                      name="newProduct[]"
                      url="/api/upload"
                      accept="image/*"
                      :maxFileSize="1000000"
                      customUpload
                      @uploader="customBase64Uploader($event, 'newProduct',selectedKey)"
                      :auto="true"
                      chooseLabel="Neue Produkte Bild ändern"
                      class="p-button-outlined text-xs p-2"
                      /><Button icon="pi pi-trash" class="p-button-danger text-xs p-2" outlined @click="selectedKey.newProductImageUrl=null"/>
                    <img
                      style="object-fit: contain"
                      :src="
                        selectedKey.newProductImageUrl ??
                        require('@/assets/no-image.svg')
                      "
                      alt="Neue Produkte Bild"
                      class="w-15rem h-15rem autofit inline-flex surface-0 text-900 px-3 py-2 border-round -mt-5 font-medium" />
                  </div>
                  <div v-else>
                  <div
                    class="p-card bg-yellow-100 p-2 text-gray-400 font-light">
                    <i class="pi pi-info-circle"></i>
                    Um Bilder für Neue Produkte zu
                    ändern, müssen '&gt;'Neue Produkte anzeigen'&lt;' in den Einstellungen aktivieren.
                  </div>
                </div>
                  <div v-if="store.companySettings?.showDiscountProduct == true" class="p-card p-2 m-1">
                    <FileUpload
                      mode="basic"
                      name="disount[]"
                      url="/api/upload"
                      accept="image/*"
                      :maxFileSize="1000000"
                      customUpload
                      @uploader="customBase64Uploader($event, 'discount',selectedKey)"
                      :auto="true"
                      chooseLabel="Rabatt Bild ändern"
                      class="p-button-outlined text-xs p-2"
                      /> <Button icon="pi pi-trash" class="p-button-danger text-xs p-2" outlined @click="selectedKey.discountImageUrl=null"/>
                    <img
                      style="object-fit: contain"
                      :src="
                        selectedKey.discountImageUrl ??
                        require('@/assets/no-image.svg')
                      "
                      alt="Rabatt Bild"
                      class="w-15rem h-15rem autofit inline-flex surface-0 text-900 px-3 py-2 border-round -mt-5 font-medium" />
                  </div>
                  <div v-else>
                  <div
                    class="p-card bg-yellow-100 p-2 text-gray-400 font-light">
                    <i class="pi pi-info-circle"></i>
                    Um Bilder für Rabatierte Produkte zu
                    ändern, müssen Sie diese in den Einstellungen aktivieren.
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onUpdated, nextTick, inject, reactive, onBeforeMount } from "vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import { useToast } from "primevue/usetoast";
import { useDialog } from "primevue/usedialog";
import { useConfirm } from "primevue/useconfirm";
import iAxios from "@/store/axiosInterface";
import fAxios from "@/store/faxiosInterface";
import { ItemsService } from "@/service/ItemsService";

const store = userStore();
const dialogRef = inject("dialogRef");
const categoryName = ref("");
const expandedKeys = ref({});
const dialog = useDialog();
const toast = useToast();
const confirm = useConfirm();
const selectedSubCategory = ref(false);
const selectedMainCategory = ref(!selectedSubCategory.value);
const categories = ref([]);
const defaultCategoryKey = ref("");

const selectedKey = ref({});
let selectedCategory = reactive({ path: "", selectedKey: "", structure: null });

onUpdated(async () => {
  console.log("🚀 ~ file: CategoryDialog.vue:198 ~   store.companySettings:",   store.companySettings)
  if (store.debug)
    console.log(
      "🚀 ~ file: CategoryDialog.vue:172 ~ categories.value:",
      categories.value
    );
    categories.value =  store.getCategories;

});


const markAsDefaultCategory = (key) => {
  defaultCategoryKey.value = key;
};


const customBase64Uploader = async (event, type, category) => {

  console.log("🚀 ~ file: CategoryDialog.vue:199 ~ category:", category)

  const logo = type === (null || "logo");
  const file = event.files[0];
  let publicUrl = "";
  const reader = new FileReader();
  reader.onloadend = async function () {
    const base64Data = reader.result;
    const fileData = new FormData();
    fileData.append("filedata", file);

    try {
      const response2 = await fAxios.post(
        'https://storage.famtura.de/api/auth/files/upload/'+store.apiKeyStorage+'/'+store.cid,
        fileData
      );

      const parts = await response2.data.fileUrl.split("files/");
      const resultURL = parts[1];
      publicUrl = "https://storage.famtura.de/api/files/" + resultURL;

      console.log(
        "🚀 ~ file: DocumentSettings.vue:1393 ~ response2.data.fileUrl:",
        response2.data
      );
      console.log("🚀 ~ file: CategoryDialog.vue:195 ~ publicUrl:", publicUrl);
      //in der jeweiligen hauptkategrie die publicurl unter dem schlüssel der in type angegeben ist speichern
      if (type === "newProduct") {
        category.newProductImageUrl = publicUrl;
      } else if (type === "discount") {
        category.discountImageUrl = publicUrl;
      }

      return publicUrl;

      // Wenn du weitere Aktionen mit der Antwort durchführen möchtest, füge den Code hier hinzu.
    } catch (error) {
      console.error(error);
    }
  };
  // Lade die Blob-Daten und starte den Konvertierungsprozess
  let blob = await fetch(file.objectURL).then((r) => r.blob());
  reader.readAsDataURL(blob);
};

const expandNode = (node) => {
  console.log("🚀 ~ file: CategoryDialog.vue:178 ~ node:", node);

  if (node?.children && node?.children.length) {
    expandedKeys.value[node.key] = true;

    for (let child of node.children) {
      expandNode(child);
    }
  }
};

const dontLetBlanc = () => {
  if (!categoryName.value) {
    selectedKey.value.label = "-";
  }
};

onBeforeMount(async () => {
  await nextTick();
  categories.value = await store.getCategories;
  defaultCategoryKey.value = store.companySettings.categories.default;
  dialogRef.value.data.categories = categories.value;
  if (store.debug)
    console.log("🚀 ~ file: CategoryDialog.vue:112 ~ dialogRef:", dialogRef);

  if (dialogRef.value.data.fromItem) {
    if (store.debug)
      console.log(
        "🚀 ~ file: CategoryDialog.vue:183 ~ dialogRef.value.data.item.category:",
        dialogRef.value.data.item.category
      );
    selectedCategory = reactive(dialogRef.value.data.item.category);
    if (store.debug)
      console.log(
        "🚀 ~ file: CategoryDialog.vue:190 ~ selectedCategory:",
        selectedCategory
      );

    if (dialogRef.value.data.item.category) {
      //First Give selectedkey The value from The item. Select The category From the For this key.
      //1. Set selectedKey.value to the value from the item

      /*       const keySelection = {
        key: dialogRef.value.data.item.category.selectedKey,
        label: dialogRef.value.data.item.category.selectedLabel,
      }; */
      selectedKey.key = dialogRef.value.data.item.category.selectedKey;
      selectedKey.label = dialogRef.value.data.item.category.selectedLabel;
      if (selectedCategory.structure) expandNode(selectedCategory.structure[0]);
      //2. die jeweilige kategorie in <Tree> auswählen mit der SelectedKey
      //keine ahnung wie ich das machen soll

      if (store.debug)
        console.log(
          "🚀 ~ file: CategoryDialog.vue:194 ~ selectedKey.value :",
          selectedKey.value
        );

      categoryName.value = selectedKey.value.label;
    }
  }
});

const changeCategoryName = () => {
  selectedKey.value.label = categoryName.value;
};

const findCategoryNameByKey = (key, categories) => {
  for (let category of categories) {
    if (category.key === key) {
      return category.label;
    }
    if (category.children) {
      let found = findCategoryNameByKey(key, category.children);
      if (found) return found;
    }
  }
  return null;
};

const findCategoryBySubPath = (key, categories, path = []) => {
  for (let i = 0; i < categories.length; i++) {
    if (categories[i].key === key) {
      return [...path, i];
    }
    if (categories[i].children) {
      let found = findCategoryBySubPath(key, categories[i].children, [
        ...path,
        i,
        "children",
      ]);
      if (found) return found;
    }
  }
  return null;
};

const findCategoryByPath = (key, categories, path = []) => {
  for (let i = 0; i < categories.length; i++) {
    const newPath = path.concat({
      key: categories[i].key,
      label: categories[i].label,
      children: [],
      discountImageUrl: categories[i].discountImageUrl,
      newProductImageUrl: categories[i].newProductImageUrl,
    });
    if (categories[i].key === key) {
      return newPath;
    }
    if (categories[i].children) {
      const found = findCategoryByPath(key, categories[i].children, newPath);
      if (found) {
        newPath[newPath.length - 1].children = [found[found.length - 1]];
        return newPath;
      }
    }
  }
  return null;
};

const getCategoryPathString = (structure) => {
  let labels = [];
  const traverse = (category) => {
    labels.push(category.label);
    if (category.children.length > 0) {
      traverse(category.children[0]);
    }
  };
  traverse(structure[0]);
  return labels.join(" > ");
};

const onCategorySelect = async (newSelectedKey) => {
  selectedKey.value = await newSelectedKey;

  if (store.debug)
    console.log("🚀 ~ file: CategoryDialog.vue:96 ~ selectedKey:", selectedKey);

  checkIfSubCategorySelected();
  if (store.debug)
    console.log(
      "🚀 ~ file: CategoryDialog.vue:106 ~ is sub?:",
      checkIfSubCategorySelected()
    );
  if (store.debug)
    console.log(
      "🚀 ~ file: CategoryDialog.vue:107 ~ has sub?:",
      hasSubCategory()
    );

  categoryName.value = selectedKey.value.label;

  // Aktualisieren Sie selectedCategory
  selectedCategory.structure = findCategoryByPath(
    selectedKey.value.key,
    categories.value
  );
  selectedCategory.path = getCategoryPathString(selectedCategory.structure);
  selectedCategory.selectedKey = selectedKey.value.key;
  selectedCategory.selectedLabel = selectedKey.value.label;

  if (store.debug)
    console.log(
      "🚀 ~ file: CategoryDialog.vue:141 ~ selectedCategory:",
      selectedCategory
    );
};

const checkIfSubCategorySelected = () => {
  if (selectedKey?.value?.key?.includes("-")) {
    selectedSubCategory.value = true;
  } else {
    selectedSubCategory.value = false;
  }
  return selectedSubCategory.value;
};

const hasSubCategory = () => {
  if (selectedKey?.value?.children?.length > 0) {
    return true;
  }
  return false;
};

const addCategory = () => {
  const newCategory = {
    key: String(categories.value.length),
    label: "Neue Kategorie",
    children: [],
    discountImageUrl: null,
    newProductImageUrl: null,
  };
  categories.value.push(newCategory);
};

const addSubCategory = () => {
  const subkey = selectedKey.value.children?.length
    ? selectedKey.value.children.length
    : 0;
  const newSubCategory = {
    key: selectedKey.value.key + "-" + subkey,
    label: "Neue Unterkategorie",
  };
  const path = findCategoryBySubPath(selectedKey.value.key, categories.value);
  if (path) {
    let category = categories.value;
    for (let i = 0; i < path.length; i++) {
      category = category[path[i]];
    }
    if (category.children) {
      category.children.push(newSubCategory);
    } else {
      category.children = [newSubCategory];
    }
  } else {
    console.error(
      "Keine Kategorie gefunden mit dem Schlüssel:",
      selectedKey.value.key
    );
  }
};

const deleteAbleCategory = () => {
  if (categories.value.length < 2) {
    toast.add({
      severity: "error",
      summary: "Kategorie nicht löschbar",
      detail: "Es muss mindestens eine Kategorie vorhanden sein",
      life: 3000,
    });
    return;
  }
  if (!hasSubCategory()) {
    confirmDelete();
  } else {
    toast.add({
      severity: "error",
      summary: "Kategorie nicht löschbar",
      detail:
        "Kategorie mit Unterkategorien können nicht gelöscht werden, es müssen zuvor die Unterkategorien gelöscht werden",
      life: 3000,
    });
  }
};

const confirmDelete = async () => {
  const items=await ItemsService.getItemsXLarge();
  if (items.filter((item) => item.category.path === selectedCategory.path).length > 0) {
    toast.add({
      severity: "error",
      summary: "Kategorie nicht löschbar",
      detail:
        "Kategorie kann nicht gelöscht werden, da sie in einem oder mehreren Produkten verwendet wird",
      life: 3000,
    });
    return;
  }
  
  confirm.require({
    message: "Möchten Sie diese Kategorie wirklich löschen?",
    header: "Bestätige Löschen",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      deleteCategory();
      toast.add({
        severity: "info",
        summary: "Kategorie gelöscht",
        detail: "Kategorie wurde erfolgreich gelöscht",
        life: 3000,
      });
    },
  });
};

const deleteCategory = () => {
  const path = findCategoryBySubPath(selectedKey.value.key, categories.value);
  if (path) {
    let category = categories.value;
    for (let i = 0; i < path.length - 1; i++) {
      category = category[path[i]];
    }
    category.splice(path[path.length - 1], 1);
  } else {
    console.error(
      "Keine Kategorie gefunden mit dem Schlüssel:",
      selectedKey.value.key
    );
  }
};

//const dialogData = dialogRef.value.data.categoryData;

const closeDialog = (data) => {
  // dialogRef.value.close(data);
};
</script>
