<template>
    <div class="loadingOverlay">
        <div class="flex justify-content-center">
            <div class="spinner-container">
                <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="200" height="200"
                    style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd"
                    viewBox="0 0 1735.67 1724.274">
                    <clipPath id="clip">
                        <!--   <path id="base" d="M169.9,73.6c9,6.2,15.3,14.9,20,23.3c4.7,8.3,7.1,17.2,7.1,26.7c0,4.9-0.7,9.6-2.1,14c-1.4,4.5-3.5,8.7-6.3,12.6
      c-7.1,9.8-16.7,17.7-28.7,23.6c-12.1,5.9-24.7,8.9-37.8,8.9c-7.9,0-15.5-1.6-23-4.8c-7.5-3.2-14.2-7.8-20.1-13.7
      c-5.8-6-10.4-12.6-13.5-20.1c-3.2-7.4-4.8-15-4.8-22.8c0-10.4,3.1-20.1,9.2-29.1c6.1-9,15.5-17.5,28-25.5c0,0,5.8-4.2,10.9-4.2
      c5.1,0,11.1,3.1,6.6,10.3c-4.5,7.2-6.8,14.3-6.8,21.1c0,6.7,2.3,12.4,6.8,16.8c4.5,4.5,10.2,6.7,17,6.7c6.2,0,11.4-2.3,15.6-7
      c4.1-4.7,6.2-10.6,6.2-17.7c0-4.9-1-9.5-3-13.7c-2-4.3-1.3-10.6,4.2-11.5C160.7,66.7,169.9,73.6,169.9,73.6z" /> -->
                        <path id="base"
                            d="M.09 6.007c1.413 3.534 42.753 86.214 91.867 184.087l89.04 177.374 685.823-.707 685.823-1.06 91.513-180.201c50.173-99.288 91.513-181.261 91.513-183.028 0-1.413-391.142-2.473-869.203-2.473C40.37-.001-2.384.353.089 6.005v.001zm237.441 469.935c0 2.12 52.293 106.354 443.789 882.63 87.274 172.78 164.301 325.775 171.014 339.908 6.714 14.133 13.427 25.794 15.193 25.794 1.413 0 2.473-216.241 2.473-480.536V763.202h482.301l73.494-144.867 73.847-144.867H868.586c-346.975 0-631.056 1.06-631.056 2.473l.001.001z"
                            style="fill:#099;fill-rule:nonzero" />

                    </clipPath>

                    <use href="#base" />

                    <path id="pth" fill="#7c7d7d" 
                    style="stroke-dasharray:3000; 
                    stroke-dashoffset:5000;" stroke="#099"
                        stroke-width="100"
                        d="M.09 6.007c1.413 3.534 42.753 86.214 91.867 184.087l89.04 177.374 685.823-.707 685.823-1.06 91.513-180.201c50.173-99.288 91.513-181.261 91.513-183.028 0-1.413-391.142-2.473-869.203-2.473C40.37-.001-2.384.353.089 6.005v.001zm237.441 469.935c0 2.12 52.293 106.354 443.789 882.63 87.274 172.78 164.301 325.775 171.014 339.908 6.714 14.133 13.427 25.794 15.193 25.794 1.413 0 2.473-216.241 2.473-480.536V763.202h482.301l73.494-144.867 73.847-144.867H868.586c-346.975 0-631.056 1.06-631.056 2.473l.001.001z"
                        clip-path="url(#clip)" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { userStore } from '@/store/userStore';
const store = userStore();
onMounted(() => {
if (store.debug) console.log("-----------Loading component mounted");
});
</script>