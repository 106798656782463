<template>
  <div class="grid align-items-center">
    <div class="text-xs xl:text-base col-4 xl:col-2">Schnellstart beim nächsten Start</div>
    <div class="col-8 xl:col-10">
      <SelectButton
        v-model="formData.fastStart"
        :options="options1"
        optionLabel="label"
        unselectable
        optionValue="value"
        aria-labelledby="basic"
        pt:label:class="text-xs xl:text-base"
        @change="handleSelectionFastStart" />
    </div>
    <div class="col-4 xl:col-2 text-xs xl:text-base">Alle Tips</div>
    <div class="col-8 xl:col-10 flex flex-col">
        <div class="">
        <Button
          icon="pi pi-eye"
          class="text-xs xl:text-base"
          size="small"
          outlined
          v-model="formData.fastStart"
          v-tooltip="'Zurücksetzen und Alle Anzeigen'"
          label="Anzeigen"
          aria-labelledby="basic"
          @click="resetTipsList" />
      </div>
      <div class="">
        <Button
          severity="danger"
          icon="pi pi-eye-slash"
          class="text-xs xl:text-base"
          size="small"
          outlined
          v-model="formData.fastStart"
          v-tooltip="'Alle Tips ausblenden'"
          label="Ausblenden"
          aria-labelledby="basic"
          @click="hideAllTips" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import * as utils from "../utils/calculationUtils";
import iAxios from "@/store/axiosInterface";

const store = userStore();
const { tipEnabledList } = storeToRefs(store);
const toast = useToast();
const dbColumns = ref([
  {
    key: "itemName",
    label: "Artikelname",
    required: true,
    allowDefaultValue: true,
  },
  { key: "itemAlias", label: "Alias" },
  { key: "showAlias", label: "Verwende Alias", allowDefaultValue: true },
  { key: "itemInPrice", label: "Einkaufspreis" },
  { key: "itemOutPrice", label: "Verkaufspreis", required: true },
]);

// Formulardaten
let formData = reactive({
  fastStart: true,
  tips: true,
});

const options1 = [
  { label: "Anzeigen", value: true },
  { label: "Nicht Anzeigen", value: false },
];

async function werteLaden() {
  const faststart = "fastStartVisible";
  formData.fastStart =
    tipEnabledList?.value?.hasOwnProperty(faststart) &&
    tipEnabledList?.value?.[faststart];
}

const handleSelectionFastStart = () => {
  if (!tipEnabledList.value) {
    tipEnabledList.value = {};
  }
  tipEnabledList.value["fastStartVisible"] = formData.fastStart;
  store.setCompanyTips(tipEnabledList.value);
  let status = formData.fastStart ? "nicht angezeigt" : "angezeigt";
  toast.add({
    severity: "success",
    summary: "Schnellstart wird beim nächsten Start",
    detail: status,
    life: 3000,
  });
};

const resetTipsList = () => {
  const oldValueFastStart = Boolean(
    tipEnabledList?.value?.["fastStartVisible"]
  );
  const tipsList = tipEnabledList.value;
  for (const key in tipsList) {
    if (Object.hasOwnProperty.call(tipsList, key)) {
      tipsList[key] = true;
    }
  }
  tipEnabledList.value.fastStartVisible = oldValueFastStart;
  store.setCompanyTips(tipEnabledList.value);
  toast.add({
    severity: "success",
    summary: "Zurücksetzen erfolgreich",
    detail: "Alle Tips werden wieder angezeigt",
    life: 3000,
  });
};
const hideAllTips = () => {
  const oldValueFastStart = tipEnabledList?.value?.["fastStartVisible"];
  const tipsList = tipEnabledList.value;
  for (const key in tipsList) {
    if (Object.hasOwnProperty.call(tipsList, key)) {
      tipsList[key] = false;
    }
  }
  tipEnabledList.value["fastStartVisible"] = oldValueFastStart;
  store.setCompanyTips(tipEnabledList.value);
  toast.add({
    severity: "success",
    summary: "Ausblenden erfolgreich",
    detail: "Alle Tips werden werden ausgeblendet",
    life: 3000,
  });
};

onMounted(() => {
  werteLaden();
});
</script>