<template v-if="extendedShopSettings?.shippingKinds?.length > 0">
  <div class="shipping-config scaleDown">
    <h2>Versandarten konfigurieren</h2>

    <DataTable
      v-if="extendedShopSettings?.shippingKinds?.length > 0"
      v-model:editingRows="editingRows"
      :value="extendedShopSettings.shippingKinds"
      editMode="row"
      @row-edit-init="onRowEditInit"
      dataKey="id"
      @row-edit-save="onRowEditSave"
      :pt="{
        table: { style: 'min-width: 50rem' },
        column: {
          bodycell: ({ state }) => ({
            style:
              state['d_editing'] &&
              'padding-top: 0.75rem; padding-bottom: 0.75rem',
          }),
        },
      }">
      <Column field="id" header="Favorit" class="text-xs w-fit pr-0">
        <template #editor="{ data, field }">
          <span v-if="extendedShopSettings.defaultShippingType != data.id">
            <Button
              outlined
              size="small"
              label="auswählen"
              icon="pi pi-star"
              severity="info"
              class="p-1 m-0 text-xs"
              @click="
                extendedShopSettings.defaultShippingType = data.id
              "></Button>
          </span>
          <span v-else class="text-blue-300 pi pi-star-fill"> </span>
        </template>
        <template #body="{ data, field, slotProps }">
          <span
            v-if="extendedShopSettings.defaultShippingType == data.id"
            class="text-blue-300 pi pi-star-fill">
          </span>
          <span
            v-if="
              !extendedShopSettings?.defaultShippingType ||
              !extendedShopSettings.shippingKinds.some(
                (method) =>
                  method.id === extendedShopSettings?.defaultShippingType
              )
            ">
            <span
              v-if="data.type === 'flat_rate'"
              class="text-blue-300 pi pi-star-fill">
            </span>
          </span>
        </template>
      </Column>

      <Column field="publicName" header="Versandart Name">
        <template #editor="{ data, field }">
          <InputText
            v-model="data[field]"
            placeholder="Versandart"
            class="text-xs" />
        </template>
      </Column>

      <Column field="type" header="Versandtyp">
        <template #body="{ data, field }">
          {{ data["name"] }}
        </template>
        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="shippingMethods"
            class="text-xs"
            inputClass="text-xs"
            optionLabel="name"
            optionValue="type"
            placeholder="Typ wählen" />
        </template>
      </Column>

      <Column field="details" header="Details">
        <template #editor="{ data }">
          <div v-if="data.type === 'flat_rate'" class="w-fit">
            <div class="flex align-items-center justify-content-end">
              <label>Pauschale (€/Bestellung):</label>
              <InputNumber
                v-model="data.details.basePrice"
                mode="currency"
                :min="0"
                inputId="currency-germany"
                class="text-xs"
                inputClass="text-xs"
                highlightOnFocus
                :allowEmpty="false"
                currency="EUR"
                locale="de-DE" />
            </div>
            <div v-if="data.details.freeShipping">
              <div class="flex align-items-center justify-content-end">
                <span
                  ><InputSwitch
                    v-model="data.details.freeShipping.enabled"
                    :binary="true"
                    v-tooltip="
                      'Kostenloser Versand ab (Brutto Bestellwert) aktivieren/deaktivieren'
                    "
                /></span>
                <label>Kostenloser Versand</label>
                <span
                  v-if="!data.details.freeShipping.enabled"
                  class="pi pi-times pl-1 text-red-300"></span>
                <span v-else class="pl-1"
                  >ab:
                  <InputNumber
                    class="text-xs"
                    inputClass="text-xs"
                    v-model="data.details.freeShipping.minAmountForFree"
                    mode="currency"
                    :min="0"
                    inputId="currency-germany"
                    highlightOnFocus
                    :allowEmpty="false"
                    currency="EUR"
                    locale="de-DE" />
                </span>
              </div>
            </div>
          </div>
          <div v-if="data.type === 'express'">
            <label>Expresskosten (€/Bestellung):</label>
            <InputNumber
              class="text-xs"
              inputClass="text-xs"
              v-model="data.details.basePrice"
              :min="0"
              highlightOnFocus
              :allowEmpty="false"
              mode="currency"
              currency="EUR"
              locale="de-DE" />
          </div>
          <div v-if="data.type === 'weight_based'">
            <label class="font-semibold">Gewichtsregeln:</label>
            <div>
              <label>Verfahrensweise bei Artikel ohne Gewichtsmaßangabe</label>
              Versand Ablehnen
              <SelectButton
                v-model="data.details.denyShippingWithoutWeight"
                :allowEmpty="false"
                optionValue="value"
                optionLabel="label"
                :options="withoutWeightItemsOptions"
                aria-labelledby="basic">
                <template #option="slotProps">
                  <span class="text-xs">{{ slotProps.option.label }}</span>
                </template>
              </SelectButton>
            </div>
            <div v-if="!data.details.denyShippingWithoutWeight">
              Gewicht vordefiniert
              <InputNumber
                class="text-xs"
                inputClass="text-xs"
                v-model="data.details.preDefinedWeightValue"
                :min="1"
                :useGrouping="false"
                highlightOnFocus
                :allowEmpty="false"
                v-tooltip="
                  'vordefiniertes Gewicht in Gramm, falls ein Artikel keine Gewichtsangabe hat.'
                "
                suffix=" gr" />
            </div>
            <div
              v-for="(rule, index) in data.details.weightRules"
              :key="index"
              class="mt-2 w-fit">
              <div class="flex justify-content-end align-items-center">
                <label>Max. Gewicht (kg):</label>
                <InputNumber
                  class="text-xs"
                  inputClass="text-xs"
                  v-model="rule.maxWeight"
                  :min="0"
                  highlightOnFocus
                  :allowEmpty="false" />
              </div>
              <div class="flex justify-content-end p-0 align-items-center">
                <Button
                  icon="pi pi-trash text-xs p-0 m-0"
                  severity="danger"
                  class="text-xs p-0 m-0"
                  text
                  @click="removeWeightRule(data, index)" />
                <label>Preis:</label>
                <InputNumber
                  class="text-xs"
                  inputClass="text-xs"
                  v-model="rule.price"
                  :min="0"
                  highlightOnFocus
                  :allowEmpty="false"
                  mode="currency"
                  currency="EUR"
                  locale="de-DE" />
              </div>
              <Divider />
            </div>
            <Button
              label="Regel hinzufügen"
              outlined
              icon="pi pi-plus text-xs"
              class="p-button-sm my-2 text-xs"
              @click="addWeightRule(data)" />
            <div
              v-if="data.details.weightRules?.length > 0"
              class="flex justify-content-end">
              <label
                >Max. Gewicht über
                {{
                  Math.max(
                    ...data.details.weightRules.map((rule) => rule.maxWeight)
                  )
                }}
                (kg) Preis/kg:</label
              >
              <InputNumber
                class="text-xs"
                inputClass="text-xs"
                v-model="data.details.overWeight.price"
                :min="0"
                highlightOnFocus
                :allowEmpty="false"
                mode="currency"
                currency="EUR"
                locale="de-DE" />
            </div>
            <div
              v-if="data.details.freeShipping"
              class="mt-2 flex align-items-center justify-content-end">
              <span cl
                ><InputSwitch
                  v-model="data.details.freeShipping.enabled"
                  :binary="true"
                  v-tooltip="
                    'Kostenloser Versand ab (Brutto Bestellwert) aktivieren/deaktivieren'
                  "
              /></span>
              <label>Kostenloser Versand ab (Brutto Bestellwert):</label>
              <span
                v-if="!data.details.freeShipping.enabled"
                class="pi pi-times pl-1 text-red-300"></span>
              <span v-else class="pl-1"
                >ab:
                <InputNumber
                  class="text-xs"
                  inputClass="text-xs"
                  v-model="data.details.freeShipping.minAmountForFree"
                  mode="currency"
                  :min="0"
                  inputId="currency-germany"
                  highlightOnFocus
                  :allowEmpty="false"
                  currency="EUR"
                  locale="de-DE" />
              </span>
            </div>
          </div>
          <div v-if="data.type === 'zone_based'">
            <label class="font-semibold">Zonen (Luftlinie in km):</label>
            <div v-for="(zone, index) in data.details.zones" :key="index">
              <div class="flex align-items-center">
                <div class="flex justify-content-start">
                  <Button
                    icon="pi pi-trash text-xs"
                    size="small"
                    text
                    severity="danger"
                    class="text-xs p-0 m-0"
                    @click="removeZone(data, index)" />
                </div>
                <div class="flex justify-content-end w-full">
                  <label>Entfernung:</label>
                  <InputNumber
                    class="text-xs"
                    inputClass="text-xs"
                    :useGrouping="false"
                    v-model="zone.distance"
                    :min="0"
                    placeholder="Entfernung in km"
                    highlightOnFocus
                    :allowEmpty="false"
                    suffix=" km"
                    locale="de-DE" />
                </div>
              </div>
              <div class="flex justify-content-end align-items-center">
                <label>Preis:</label>
                <InputNumber
                  class="text-xs"
                  inputClass="text-xs"
                  v-model="zone.price"
                  :min="0"
                  highlightOnFocus
                  :allowEmpty="false"
                  mode="currency"
                  currency="EUR"
                  locale="de-DE" />
              </div>
              <div
                v-if="zone.freeShipping"
                class="flex justify-content-end align-items-center">
                <span
                  ><InputSwitch
                    v-model="zone.freeShipping.enabled"
                    :binary="true"
                    v-tooltip="
                      'Kostenloser Versand ab (Brutto Bestellwert) aktivieren/deaktivieren'
                    "
                /></span>
                <label>Kostenloser Versand ab:</label>
                <span
                  v-if="!zone.freeShipping?.enabled"
                  class="pi pi-times pl-1 text-red-300"></span>
                <span v-else class="pl-1"
                  >ab:
                  <InputNumber
                    class="text-xs"
                    inputClass="text-xs"
                    v-model="zone.freeShipping.minAmountForFree"
                    mode="currency"
                    :min="0"
                    inputId="currency-germany"
                    highlightOnFocus
                    :allowEmpty="false"
                    currency="EUR"
                    locale="de-DE" />
                </span>
              </div>
              <Divider />
            </div>
            <Button
              label="Zone hinzufügen"
              icon="pi pi-plus"
              class="p-button-sm"
              @click="addZone(data)" />
          </div>
          <div v-if="data.type === 'pickup'">
            <label>Standort:</label>
            <InputText
              v-model="data.details.location"
              placeholder="Standort eingeben" />
          </div>
          <div v-if="data.type === 'oversized'">
            <label>Sperrgutzuschlag (€):</label>
            <InputNumber
              class="text-xs"
              inputClass="text-xs"
              v-model="data.details.basePrice"
              :min="0"
              highlightOnFocus
              :allowEmpty="false"
              mode="currency"
              currency="EUR"
              locale="de-DE" />
          </div>
          <div>
            <label>Liefer-/Versandzeit:</label>
            <InputText
              v-model="data.details.shippingTime"
              placeholder="Bsp. 1-3 Werktage"
              class="text-xs" />
          </div>
        </template>
        <template #body="{ data }">
          <div v-if="data.type === 'flat_rate'">
            Pauschale: {{ formatCurrency(data.details.basePrice) }}
            <div v-if="data.details.freeShipping">
              Kostenloser Versand
              <span
                v-if="!data.details.freeShipping.enabled"
                class="pl-1 pi pi-times text-red-300"></span>
              <span v-else class="pl-1">
                ab:
                {{ formatCurrency(data.details.freeShipping.minAmountForFree) }}
              </span>
            </div>
          </div>
          <span v-if="data.type === 'express'"
            >Express: €{{ data.details.basePrice }}</span
          >
          <div v-if="data.type === 'weight_based'">
            <span
              v-for="(rule, index) in data.details.weightRules"
              :key="index">
              Bis {{ rule.maxWeight }}kg: {{ formatCurrency(rule.price) }}<br />
            </span>
            <div v-if="data.details.freeShipping">
              Kostenloser Versand
              <span
                v-if="!data.details.freeShipping.enabled"
                class="pl-1 pi pi-times text-red-300"></span>
              <span v-else class="pl-1">
                ab: €{{ data.details.freeShipping.minAmountForFree }}
              </span>
            </div>
          </div>
          <div v-if="data.type === 'zone_based'">
            <span v-for="(zone, index) in data.details.zones" :key="index">
              bis {{ zone.distance }} km: {{ formatCurrency(zone.price) }}<br />
              <div v-if="zone?.freeShipping">
                Kostenloser Versand
                <span
                  v-if="!zone?.freeShipping?.enabled"
                  class="pl-1 pi pi-times text-red-300"></span>
                <span v-else class="pl-1">
                  ab: {{ formatCurrency(zone?.freeShipping?.minAmountForFree) }}
                </span>
              </div>
            </span>
          </div>
          <span v-if="data.type === 'pickup'"
            >Standort: {{ data.details.location }}</span
          >
          <span v-if="data.type === 'oversized'"
            >Sperrgutzuschlag:
            {{ formatCurrency(data.details.basePrice) }}</span
          >
          <div v-if="data.details.shippingTime?.length">
            <span>Liefer-/Versandzeit: </span>
            {{ data.details.shippingTime }}
          </div>
        </template>
      </Column>

      <Column field="available" header="Aktiv">
        <template #editor="{ data, field }">
          <Checkbox v-model="data[field]" :binary="true" />
        </template>
        <template #body="{ data, field }">
          <i
            :class="['pi', data[field] ? 'pi-check' : 'pi-times']"
            style="font-size: 1.2rem"></i>
        </template>
      </Column>
      <Column field="public" header="Öffentlich">
        <template #editor="{ data, field }">
          <Checkbox v-model="data[field]" :binary="true" />
        </template>
        <template #body="{ data, field }">
          <i
            :class="['pi', data[field] ? 'pi-check' : 'pi-times']"
            style="font-size: 1.2rem"></i>
        </template>
      </Column>

      <Column
        header="Aktionen"
        :rowEditor="true"
        style="width: 1%; min-width: 2rem"
        bodyStyle="text-align:center">
      </Column>
      <Column style="width: 1%; min-width: 2rem" bodyStyle="text-align:center">
        <template #body="rowData">
          <Button
            v-tooltip="'Löschen'"
            text
            icon="pi pi-trash"
            class="p-button-danger"
            @click="removeMethod(rowData)" />
        </template>
      </Column>
      <template #footer>
        <div
          class="flex flex-fluid justify-content-end align-items-center gap-4">
          <div>
            <Button
              label="Neue Versandart hinzufügen"
              class="text-xl xl:text-xs"
              icon="pi pi-plus text-xl xl:text-xs"
              @click="addShippingMethod" />

            <!--             <Button
              outlined
              label="Einstellungen speichern"
              icon="pi pi-save text-xl xl:text-xs"
              class="p-button-success text-xl xl:text-xs"
              @click="saveForm" /> -->
          </div>
        </div>
      </template>
    </DataTable>
    <div
      class="card flex align-items-center justify-content-end w-full mt-5 text-3xl font-bold"
      v-if="store.debug">
      Debug:<Button
        @click="saveForm(true)"
        outlined
        severity="warning"
        label="shippingKinds Reset und speichern" />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { shippingMethods } from "@/utils/shippingUtils.js";
import { userStore } from "@/store/userStore";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import { SettingsExtended } from "@/service/SettingsExtended";
import { formatCurrency } from "@/utils/formatUtils";

const toast = useToast();
const store = userStore();
const extendedShopSettings = inject("extendedShopSettings");

const editingRows = ref([]);
const withoutWeightItemsOptions = ref([
  { label: "Versand ablehnen", value: true },
  { label: "Mit vordefiniertem Gewicht berechnen", value: false },
]);
const onRowEditInit = (event) => {
  const data = event.data;

  // Bedingung prüfen
  if (
    (data.type === "weight_based" &&
      !store.rules.activatedModules.shippingWeight) ||
    (data.type === "zone_based" &&
      !store.rules.activatedModules.shippingZone) ||
    (data.type === "live_rate" &&
      !store.rules.activatedModules.shippingLiveRate) ||
    (data.type === "oversized" &&
      !store.rules.activatedModules.shippingOversized)
  ) {
    // Toast-Nachricht anzeigen
    upgradeShippingMethod(data.type);

    // Bearbeitung verhindern
    const rowIndex = editingRows.value.findIndex((row) => row.id === data.id);
    if (rowIndex !== -1) {
      editingRows.value.splice(rowIndex, 1);
    }
  } else {
    // Bearbeiten erlauben
    if (!editingRows.value.some((row) => row.id === data.id)) {
      editingRows.value.push(data);
    }
  }
};

const addWeightRule = (data) => {
  if (!data.details.weightRules) data.details.weightRules = [];
  data.details.weightRules.push({ maxWeight: 10, price: 10 });
};

const removeWeightRule = (data, index) => {
  data.details.weightRules.splice(index, 1);
};

const addShippingMethod = () => {
  const newEntry = {
    id: uuidv4(),
    name: "Standardversand",
    publicName: "Standardversand",
    type: "flat_rate",
    details: {
      basePrice: 5.99, // Einheitlicher Preis für flatrate
      freeShipping: {
        enabled: true,
        minAmountForFree: 150,
      },
    },
    available: true,
    public: true,
  };
  extendedShopSettings.shippingKinds.push(newEntry);
  //editingRows.value.push(newEntry.id);
  editingRows.value.push(newEntry);
};

const addZone = (data) => {
  if (!data.details.zones) data.details.zones = [];
  data.details.zones.push({
    distance: "",
    price: 0,
    freeShipping: { enabled: false, minAmountForFree: 0 },
  });
};

const removeZone = (data, index) => {
  data.details.zones.splice(index, 1);
};

const removeMethod = (rowData) => {
  console.log("🚀 ~ file: Versandkosten.vue:477 ~ rowData:", rowData);

  if (rowData.data.deletable === false) {
    toast.add({
      severity: "error",
      summary: "Kann nicht entfernt",
      detail:
        "Diese Versandart kann nicht entfernt werden, Sie können es aber deaktivieren.",
      life: 5000,
    });
  } else {
    const index = rowData.index;
    extendedShopSettings.shippingKinds.splice(index, 1);
  }
};

const onRowEditSave = (event) => {
  const { newData, index } = event;
  extendedShopSettings.shippingKinds[index] = newData;
  saveForm();
};

const saveForm = async (del) => {
  if (del == true) delete extendedShopSettings.shippingKinds;
  await SettingsExtended.setSettingsExtended({ extendedShopSettings })
    .then(async (res) => {
      setTimeout(() => {
        toast.add({
          severity: "success",
          summary: "Erfolgreich",
          detail: "Daten gespeichert",
          life: 3000,
        });
      }, 100);
      // console.log(res);
    })
    .catch((err) => {
      console.log(err);
    })
    .then(() => {});
};

const upgradeShippingMethod = (paket) => {
  let buchen = "shippingWeight";
  if (paket == "zone_based") buchen = "shippingZone";
  if (paket == "live_rate") buchen = "shippingLiveRate";
  if (paket == "oversized") buchen = "shippingOversized";
  toast.add({
    severity: "warn",
    summary: "Fehlendes Paket",
    detail:
      "rule." +
      buchen +
      " In Ihrem Paket nicht enthalten. Sie können es dazu buchen oder ihr Paket upgraden.",
    life: 8000,
  });
};
onMounted(() => {
  store.rules.activatedModules.shippingOversized = true;
});
</script>

<style scoped>
.shipping-config {
  position: relative;
  margin-top: 0;
  padding: 0;
  transform-origin: top left;
  transition: all 0.3s ease;
}

.scaleDown {
  transform: scale(0.5);
  width: 200%;
  position: relative;
  height: 350px;
}

@media (min-width: 1280px) {
  .scaleDown {
    transform: scale(1);
    width: 100%;
    height: auto;
  }
}
</style>
