<template>
  <div class="card">
    <Menubar
      :model="menuItems" >
      <template #item="{ item, props, hasSubmenu, root }">
        <a v-ripple class="flex align-items-center" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <Badge v-if="item.badge" :class="{ 'ml-auto': !root, 'ml-2': root }" :value="item.badge" />
            <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>
            <i v-if="hasSubmenu" :class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
        </a>
    </template>
    </Menubar>
  </div>
  <vue3-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="true"
    :paginate-elements-by-height="1100"
    filename="Packliste"
    :margin="0"
    :pdf-quality="1"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    :key="pdfKey"
    pdf-content-width="210mm"
    @progress="onProgress($event)"
    @hasStartedGeneration="hasStartedGeneration()"
    @hasGenerated="hasGenerated($event)"
    ref="html2PdfComponentRef">
    <template v-slot:pdf-content>
      <!-- PDF Content  -->
      <div>
        <div class="etiketten-page" id="etiketten-page">
          <div v-for="(etikett, index) in props.etikettenDaten">
            <div v-if="!isToMuchItems(etikett)">
              <div
                class="etiketten-container"
                :key="index"
                :class="{
                  'left-column': index % 2 === 0,
                  'right-column': index % 2 !== 0,
                  'add-margin-top':
                    (index % 10 === 0 && index !== 0) ||
                    (index % 11 === 0 && index !== 0),
                }">
                <div v-if="etikett.itemList" class="etiketten">
                  <!-- Etiketteninhalt -->
                  <div class="etiketten-content">
                    <div class="qr-code" :style="etikett.qrCodePosition">
                      <img :src="etikett.qrCodeImageUrl" alt="QR Code" />
                    </div>
                    <div class="boxTitel">
                      <strong>{{ etikett.deliveryDocumentId }}</strong>
                      <span
                        style="
                          display: inline-block;
                          width: 5rem;
                          text-align: right;
                        "
                        >∑{{ getTotalGewicht(etikett) }}kg</span
                      >
                      &nbsp;{{ etikett.boxtype }}
                    </div>
                    <div class="boxTitel">{{ etikett.name1 }}</div>
                    <div v-if="etikett.deliverStatus != 'in Bearbeitung'">
                      <strong>{{ etikett.deliverStatus }}</strong>
                    </div>
                    <ul class="artikelPositionen">
                      <li
                        v-for="(
                          artikelPosition, artikelPositionIndex
                        ) in etikett.itemList"
                        :key="artikelPositionIndex">
                        <span class="abgeschnittener-text"
                          >&nbsp; {{ artikelPosition.storagePlace }}|{{
                            artikelPosition.quantity
                          }}*<small>{{ artikelPosition.itemUnit }}</small
                          >|
                          {{
                            artikelPosition.itemAlias
                              ? artikelPosition.itemAlias
                              : artikelPosition.itemName
                          }}</span
                        >&nbsp;<span style="font-size: x-small"
                          ><i>{{ getGewicht(artikelPosition) }}</i></span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="toMuchItems">
        <!-- mehr als 8 Artikel-->
        <div class="html2pdf__page-break" />
        <div>
          <div class="etiketten-page" id="etiketten-page">
            <div v-for="(etikett, index) in props.etikettenDaten">
              <div v-if="etikett.itemList.length >= 8">
                <div
                  class="etiketten-container-long"
                  :key="index"
                  :class="{
                    'left-column': index % 2 === 0,
                    'right-column': index % 2 !== 0,
                    'add-margin-top':
                      (index % 10 === 0 && index !== 0) ||
                      (index % 11 === 0 && index !== 0),
                  }">
                  <div v-if="etikett.itemList" class="etiketten">
                    <!-- Etiketteninhalt -->
                    <div class="etiketten-content">
                      <div class="qr-code" :style="etikett.qrCodePosition">
                        <img :src="etikett.qrCodeImageUrl" alt="QR Code" />
                      </div>
                      <div class="boxTitel">
                        <strong>{{ etikett.deliveryDocumentId }}</strong
                        >&nbsp;<span>kg:{{ getTotalGewicht(etikett) }}</span
                        >{{ etikett.boxtype }}
                      </div>
                      <div class="boxTitel">
                        {{ etikett.firstName }} {{ etikett.surName }}
                      </div>
                      <div v-if="etikett.deliverStatus != 'in Bearbeitung'">
                        <strong>{{ etikett.deliverStatus }}</strong>
                      </div>
                      <ul class="artikelPositionen">
                        <li
                          v-for="(
                            artikelPosition, artikelPositionIndex
                          ) in etikett.itemList"
                          :key="artikelPositionIndex">
                          <span class="abgeschnittener-text"
                            >&nbsp; {{ artikelPosition.storagePlace }}|{{
                              artikelPosition.quantity
                            }}*<small>{{ artikelPosition.itemUnit }}</small
                            >|
                            {{
                              artikelPosition.itemAlias
                                ? artikelPosition.itemAlias
                                : artikelPosition.itemName
                            }}</span
                          >&nbsp;<span style="font-size: x-small"
                            ><i>{{ getGewicht(artikelPosition) }}</i></span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- mehr als 8 Artikel bereich ende-->
      <!-- PDF Content  -->
    </template>
  </vue3-html2pdf>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import QRCode from "qrcode";
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const router = useRouter();
const store = userStore();
const { headerTitel } = storeToRefs(store);

const toast = useToast();
const toMuchItems = ref(false);
const html2PdfComponentRef = ref(null);
const pdfKey = ref(0);
const packlistenButton = ref(true);
const props = defineProps({
  packlistenButtonDisabled: {
    type: Boolean,
    required: false,
    default: true,
  },
  etikettenDaten: Array,
});

const isToMuchItems = (etikett) => {
  const toMuch = etikett.itemList.length > 8;
  toMuchItems.value = toMuch || toMuchItems.value;
  return toMuch;
};

const progressState = ref("");

const menuItems = ref([
  {
    label: "Versand",
    icon: "pi pi-qrcode",
    url: "/shipping",
    command: () => {
      versandScan();
    },
  },
  {
    label: "Packliste Drucken",
    icon: "pi pi-print",
    command: () => {
      generateReport();
    },
  },
]);

const generateQRCode = (etikett) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    QRCode.toCanvas(
      canvas,
      etikett.deliveryDocumentId.toString(),
      { width: 65 },
      (error) => {
        if (error) {
          console.error("Fehler beim Generieren des QR-Codes:", error);
          reject(error);
        } else {
          const qrCodeImageUrl = canvas.toDataURL("image/png");
          etikett.qrCodeImageUrl = qrCodeImageUrl;
          resolve(qrCodeImageUrl);
        }
      }
    );
  });
};

const generateQRCodeForEtiketten = () => {
  const arr = Array.from(props.etikettenDaten);
  arr.forEach((etikett) => {
    generateQRCode(etikett).catch((error) => {
      console.error("Fehler beim Generieren der QR-Codes:", error);
    });
  });
};

const getTotalGewicht = (etikett) => {
  if (!etikett.itemList) {
    return 0;
  }
  let totalGewicht = 0.0;
  etikett.itemList.forEach((item) => {
    if (!item.itemParameters) {
      return;
    }
    totalGewicht += item.quantity * item.itemParameters.weight;
  });
  return totalGewicht;
};

const getGewicht = (item) => {
  let Gewicht = 0.0;
  if (!item.itemParameters) {
    return;
  }
  Gewicht += item.quantity * item.itemParameters.weight;
  return Gewicht > 0 ? "=" + Gewicht.toFixed(2) + "kg" : "";
};

const generateReport = async () => {
  if (props.etikettenDaten.length <= 0) {
    toast.add({
      severity: "error",
      summary: "Keine Pakete für die Packliste gewählt",
      detail: "Es muss vorher mindestens eine Sendung ausgewählt werden.",
      life: 2000,
    });
    return;
  }

  await generateQRCodeForEtiketten();
  await pdfKey.value++; //erneutes Rendern erzwingen, da qrcodes zur renderzeit nicht vorlagen
  await nextTick();
  const html2PdfComponent = html2PdfComponentRef.value;
  if (html2PdfComponent) {
    html2PdfComponent.generatePdf();
  } else {
    console.error("Vue3Html2pdf-Element nicht gefunden.");
  }
};

const versandScan = () => {
  headerTitel.value = "VERSAND & QR SCANNER";
  router.push("/shipping");
};

const onProgress = (progress) => {
  progressState.value = `Packliste Generierung Fortschritt: ${progress}%`;
};
</script>

<script>
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  components: {
    Vue3Html2pdf,
  },
};
</script>

<style scoped>
.etiketten-page {
  width: 210mm;
  /* DIN-A4-Breite */
  /* DIN-A4-Höhe */
  display: flex;
  flex-wrap: wrap;
  /* Abstand vom Seitenrand */
  margin-top: 10mm;
  margin-right: 5mm;
  margin-bottom: 10mm;
  margin-left: 5mm;
}

.etiketten-container {
  width: 100mm;
  /* 2 Spalten, je 50% Breite mit 5mm Abstand zwischen den Spalten */
  height: 57mm;
  /* Etikettenhöhe */
  margin: 0mm 0mm 0 0mm;
  /* Abstand zwischen den Etiketten */
  border: 0.2px dashed #c7c5c5;
  /* Beispielrahmen für die Etiketten */
}

.etiketten-container-long {
  width: 100mm;
  /* 2 Spalten, je 50% Breite mit 5mm Abstand zwischen den Spalten */
  height: 100%;
  /* Etikettenhöhe */
  margin: 0mm 0mm 0 0mm;
  /* Abstand zwischen den Etiketten */
  border: 0.2px dashed #c7c5c5;
  /* Beispielrahmen für die Etiketten */
}

.left-column {
  margin-right: 0mm;
  /* Abstand zwischen den Spalten */
}

.right-column {
  margin-left: 0mm;
  /* Abstand zwischen den Spalten */
}

.add-margin-top {
  margin-top: 12mm;
  /* Fügt den Abstand nach 10 Etiketten hinzu */
}

ul.artikelPositionen {
  list-style-type: none;
  /* Entfernt die standardmäßigen Aufzählungszeichen */
  padding-left: 0;
  /* Entfernt den standardmäßigen linken Einzug */
  top: -18mm;
  position: relative;
  /* Positioniert den begin der  Aufzählungs*/
}

ul.artikelPositionen li {
  margin-left: 5mm;
  /* Fügt den linken Einzug für den Text hinzu (15mm) */
  display: flex;
  /* Verwendet Flexbox-Layout */
  align-items: center;
  /* Zentriert den Text vertikal */
  position: relative;
  /* Positioniert das Aufzählungszeichen  */
}

ul.artikelPositionen li::before {
  content: "\25A1";
  /* Setzt ein leeres Quadrat als Aufzählungszeichen */
  position: absolute;
  /* Positioniert das Aufzählungszeichen absolut */
  left: -5mm;
  /* Verschiebt das Aufzählungszeichen nach links (15mm) */
  top: -2.5mm;
  font-size: 20pt;
  /* Legt die Schriftgröße für das Aufzählungszeichen fest */
}

/* Weitere CSS-Stile für Etiketteninhalte, QR-Codes usw. */
div.qr-code {
  position: relative;
  left: 82mm;
}

div.boxTitel {
  position: relative;
  top: -18mm;
}

button {
  margin-top: 10mm;
  /* Abstand zwischen den Etiketten und dem Button */
}
</style>
